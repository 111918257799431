



import React, {useCallback, useState, useRef, useEffect} from "react";
import {GuestBookDto} from "../Dto/GuestBookDto";
import {useForm} from "react-hook-form";
import {Fab} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {Cancel} from "@mui/icons-material";
import {UploadRezept} from "../Rezepte/UploadRezept";

interface EditComponentModelProps<T> {
    dto?:T
    saveDto?: (obj:T |undefined) => void
    visible:boolean
    /*retFunc:()=>React.DOMElement<any, any>*/
}

export function EditGuestBookComponentModel(props:EditComponentModelProps<GuestBookDto>) {
    const {dto, saveDto, visible} = props;
    const [name  , setName] = useState<string | undefined>(props.dto?.name);
    const [text  , setText] = useState<string | undefined>(props.dto?.text);
    const [company  , setCompany] = useState<string | undefined>(props.dto?.company);
    const [bild  , setBild] = useState<string | undefined>(props.dto?.image);

    /*
        useEffect wird nur aufgerufen, wenn sich dto ändert und dann wird die Form neu initialisiert
     */
    useEffect(() => {
        reset(dto);
        setBild(props.dto?.image);

    },[dto  ]);

    const {
        register
        , setError
        , handleSubmit
        , formState: {errors}
        ,reset
    } = useForm({
        defaultValues: {
            name: props?.dto?.name,
            text: props?.dto?.text,
            company: props?.dto?.company,
            image: props?.dto?.image,
            id: props?.dto?.id
        }
    });

    const onSubmit = (data: any) => {
        let temp:GuestBookDto ={
            id:data.id,
            name:data.name,
            text:data.text,
            company:data.company,
            image:bild,
            isActivated:false,
            created: new Date(Date.now())
        }
       if (saveDto){
            saveDto(temp);
            setBild(undefined)
        }

    }

    const inputElement = useRef<HTMLInputElement>(null);

    const setImageFunc = (img?:string ) =>{
        setBild(img);
    }



    return (
        <div className={   visible ? "Register-row" : "hidden"}>

            <form className="Register-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="Register-row">
                    <div className="Register-row">
                        <label className="Register-label" >Name</label>
                        <input className="input" {...register('name', {required: true})} />
                    </div>
                    <div className="Register-row">
                        <label className="Register-label" >Firma</label>

                        <input className="input"   {...register('company', {required: false})}  />
                    </div>
                    <div className="Register-row">
                        <label className="Register-label" >Text</label>
                        <textarea  rows={5} cols={50}  {...register('text', {required: true})}></textarea>
                    </div>
                    <div className="Register-row hidden">
                        <label className="Register-label" >Bild</label>
                        <input className="input"   {...register('image', {required: false})}  />
                    </div>
                    <div  className="Register-col hidden">
                        <div className="Register-row-container just-even">
                            <input type={"submit"} ref={inputElement}/>
                        </div>
                    </div>

                    <div className="Register-row">
                        <UploadRezept setImage={setImageFunc} image={bild}/>
                    </div>
                    <div>
                        <Fab color="primary" size="small" aria-label="add"
                             onClick={(e)=> {

                                 inputElement.current?.click();
                             }}  >
                            <DoneIcon />
                        </Fab>
                        <Fab color="primary" size="small" aria-label="add"
                             onClick={(e)=> {
                                 setBild(undefined)
                                 if(saveDto)
                                     saveDto(undefined)
                             }}  >
                            <Cancel />
                        </Fab>

                    </div>
                </div>

            </form>

        </div>
    );
}



