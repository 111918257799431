import React, {useState} from "react";
export class  GuestBookActivateDto
{

    constructor(
        id:string ,

    isActivated: boolean,

    toDelete: boolean

    ) {
        this.id=id
        this.isActivated=isActivated
        this.toDelete=toDelete
    }

    id:string ;

    isActivated: boolean;

    toDelete: boolean;
}