import {Kunde} from "./Kunde";
import {IBase} from "./base";

export interface IKundenliste {
    kunden: Kunde[],
    defaultKunde: Kunde | undefined
}

export class Kundenliste implements IKundenliste {
    get kunden(): Kunde[] {
        return this._kunden;
    }

    set kunden(value: Kunde[]) {
        this._kunden = value;
    }
    private _kunden: Kunde[];

    constructor() {
        this._kunden=[];
    }


    public  addKunde = (a: Kunde) =>  {
        this._kunden.push(a);
    }

    deleteKunde(a: Kunde): void {
        let edited=this._kunden.find(x => x.id === a.id );
        if(edited !== undefined)
        {
            this._kunden.forEach( (item, index) => {
                if(item === a) this._kunden.splice(index,1);
            });
        }
    }

    defaultKunde: Kunde | undefined;
}
