import {date} from "yup";
import {SpeisekartePositionWriteDto} from "./SpeisekartePositionDto";
import {SpeisekartePositionReadDto} from "./SpeisekartePositionDto";
import {forEach} from "react-bootstrap/ElementChildren";
import {json} from "react-router-dom";

export class SpeisekarteWriteDto {
    static fromDto(dto: SpeisekarteReadDto): SpeisekarteWriteDto {
        const wDto = new SpeisekarteWriteDto(
            dto.id
            ,dto.name
            ,dto.validFrom
            ,dto.validUntil
            ,dto.kartenTyp
            ,Object.assign([])
        )

        dto.positionen.forEach((item) => {
            const pos = new SpeisekartePositionWriteDto(
                item.id,
                item.speisekarteId,
                item.beschreibung,
                item.gruppe,
                item.order,
                item.preis,
                item.rezeptId
            )
            wDto.positionen.push(pos)
        })
        console.log(JSON.stringify(wDto))
        return wDto;
    }

    constructor(
        id: string | undefined,
        name: string | undefined,
        validFrom: string | undefined,
        validUntil: string | undefined,
        kartenTyp: number | undefined,
        positionen: SpeisekartePositionWriteDto[]
    ) {
        this.id = id
        this.name=name
        this.validFrom=validFrom
        this.validUntil=validUntil
        this.kartenTyp=kartenTyp
        this.positionen=Object.assign([], positionen)
    }



    id: string | undefined
    name: string | undefined
    validFrom: string | undefined
    validUntil: string | undefined
    kartenTyp: number | undefined
    positionen: SpeisekartePositionWriteDto[]

}


export class SpeisekarteReadDto {

    constructor(
        id: string | undefined,
        name: string | undefined,
        validFrom: string | undefined,
        validUntil: string | undefined,
        kartenTyp: number | undefined,
        positionen: SpeisekartePositionReadDto[]
    ) {
        this.id = id
        this.name=name
        this.validFrom=validFrom
        this.validUntil=validUntil
        this.kartenTyp=kartenTyp
        this.positionen=Object.assign([], positionen)
    }

    id: string | undefined
    name: string | undefined
    validFrom: string | undefined
    validUntil: string | undefined
    kartenTyp: number | undefined
    positionen: SpeisekartePositionReadDto[]

}
