import {UUID} from "crypto";
import {Base, IBase} from "./base";
import {Adresse} from "./Adresse";
import {Adressenliste, IAdressenliste} from "./AdressenListe";


export interface IKunde extends IBase
{
    userid:string;
    title:string | undefined;
    name:string;
    email:string;
    password:string;
    telefon:string | undefined;
    isActivated:boolean;
    adressen: Adressenliste;

    lieferAdresse: Adresse |undefined;
}

export class Kunde extends Base implements IKunde {
    get lieferAdresse(): Adresse|undefined {
        return this._lieferAdresse;
    }

    set lieferAdresse(value: Adresse |undefined) {
        this._lieferAdresse = value;
    }

     constructor(id: string| undefined) {
         super(id)
        this._adressen = new Adressenliste();
        this._userid='';
        this._email='';
        this._password='';
        this._name='';

    }
    get userid(): string {
        return this._userid;
    }

    set userid(value: string) {
        this._userid = value;
    }
    get title(): string | undefined {
        return this._title;
    }

    set title(value: string | undefined) {
        this._title = value;
    }
    get telefon(): string | undefined {
        return this._telefon;
    }

    set telefon(value: string | undefined) {
        this._telefon = value;
    }
    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }
    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
    get isActivated(): boolean {
        return this._isActivated;
    }

    set isActivated(value: boolean) {
        this._isActivated = value;
    }
    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }
    get adressen(): Adressenliste {
        return this._adressen;
    }

    set adressen(value: Adressenliste) {
        this._adressen = value;
    }

    public AddAdresse(adresse: Adresse) {
        this._adressen.adressen.push(adresse);
    }

    private _adressen: Adressenliste;
    private _email!: string;
    private _isActivated: boolean = false;
    private _name!: string;
    private _password!: string;
    private _telefon: string | undefined;
    private _title: string | undefined;
    private _userid!: string;
    private _lieferAdresse: Adresse |undefined;
}
