import {createTheme, PaletteColorOptions} from "@mui/material";
import {blue, orange, red, lightBlue} from "@mui/material/colors";
export const theme = createTheme({
    palette: {
        primary: {
            main : orange[200]

        },
        secondary: {
            main: blue[500]
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    }
});

