import React from "react";

export function Legende() {
    return (
        <div>
            <h5>Legende Inhaltsstoffe</h5>
            <div className="d-flex flex-row">
                <div className="w-auto px-3">1)</div>
                <div className="w-auto">Rahm</div>
            </div>
            <div className="d-flex flex-row">
                <div className="w-auto px-3">2)</div>
                <div className="w-auto">Butter</div>
            </div>
            <div className="d-flex flex-row">
                <div className="w-auto px-3">3)</div>
                <div className="w-auto">Weizenmehl</div>
            </div>
            <div className="py-1">Wir geben Ihnen gerne detaillierte Informationen über mögliche Allergene in den einzelnen Produkten.</div>
            <div>Da wir individuell kochen, können wir uns Ihren Anforderungen anpassen.</div>
        </div>
    );
}