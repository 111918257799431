export class RezeptDto  {

    constructor(id:string,
                name:string,
                description:string,
                mindestpreis: number,
                image?:string
    ) {
        this.id=id
        this.name = name
        this.description=description
        this.image = image
        this.mindestpreis = mindestpreis

    }

    id:string ;
    name : string;
    description: string;
    image?: string  ;
    mindestpreis: number

}