import { isUuid } from 'uuidv4';
export {};
export interface IBase
{
    id:string| undefined
}

export  class Base implements IBase {
    get id(): string| undefined {
        return this._id;
    }

    set id(value: string| undefined) {
  /*      if(!value === undefined)  {
            if(!isUuid(typeof value === "string" ? value :"")){
                throw new Error("value is not UUID");
            }
        }*/
        this._id = value;
    }
    private _id: string| undefined;

    constructor(id: string| undefined) {
        this._id= id;
    }


}