import {SpeisekarteReadDto, SpeisekarteWriteDto} from "../Dto/SpeisekarteDto";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {Simulate} from "react-dom/test-utils";
import reset = Simulate.reset;
import {Anmeldedaten} from "../../Model/Anmeldedaten";
import {RezeptDto} from "../Dto/RezeptDto";
import {ErrorMessage, Field, Form, Formik, FormikProps, FormikValues} from "formik";
import {DatePickerField} from "./DatePickerField";
import TimePickerField from "./TimePickerField";
import {Adresse} from "../../Model/Adresse";
import {v4} from "react-native-uuid/dist/v4";
import {Fab} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {Cancel} from "@mui/icons-material";
import {SpeisekartePositionManagerComponent} from "./SpeisekartePositionManagerComponent";
import {SpeisekartePositionWriteDto} from "../Dto/SpeisekartePositionDto";


interface EditSpeisekarteComponentModelProps {
    dto?:SpeisekarteWriteDto
    saveDto?: (obj:SpeisekarteWriteDto | undefined) => void
    visible:boolean
    /*retFunc:()=>React.DOMElement<any, any>*/
}



export function EditSpeisekarteComponentModel(props:EditSpeisekarteComponentModelProps) {
    const {dto, saveDto, visible} = props;
    const [dtoState, setDtoState] = useState<SpeisekarteWriteDto|undefined>();
    const [positionList, setPositionList] = useState<SpeisekartePositionWriteDto[]>([])

 /*   const GetSpeisekarte=() => {
        if (typeof id === "string" &&
            typeof name === "string" &&
            typeof validFrom === "string" &&
            typeof validUntil === "string" &&
            typeof kartenTyp === "string"
        ) {
            const obj = props.dto === undefined ?
                new SpeisekarteWriteDto(
                    id,
                    name,
                    validFrom,
                    validUntil,
                    kartenTyp,
                    positionList === undefined ? [] : positionList
                )
                : props.dto;
            return obj;
        }
        return null;
    };
*/
    const initialValues = {
        id:dto?.id,
        name: dto?.name,
        validFrom:dto?.validFrom,
        validUntil: dto?.validUntil,
        kartenTyp: dto?.kartenTyp
    }

    function sanitizeInitialValues(valuesObject: FormikValues): string {
        return JSON.parse(
            JSON.stringify(valuesObject, (_key, value) => {
                return value === null ? '' : value;
            })
        );
    }
    sanitizeInitialValues(initialValues)


    const {
        register
        , setError
        , handleSubmit
        , formState: {errors}
        ,reset
    } = useForm({
        defaultValues: initialValues
    });


    if((dto?.id !== dtoState?.id) ) {
        setDtoState(dto)
        reset()
    }
    if (dto) {
        if (!dtoState) {
            setDtoState(dto)
            // @ts-ignore
            reset({
                id: dto?.id,
                name: dto?.name,
                validFrom: dto?.validFrom,
                validUntil: dto?.validUntil,
                kartenTyp: dto?.kartenTyp
            })
        }
    }

    const onSubmit = (data: any) => {
        console.log("Submitted")

        let temp:SpeisekarteWriteDto ={
            id:data.id,
            name:data.name,
            validFrom:data.validFrom,
            validUntil:data.validUntil,
            kartenTyp:data.kartenTyp,
            positionen:positionList === undefined ? [] : positionList
        }
        if (saveDto){
            saveDto(temp);
        }
    }

    const renderError = (message:string) => <div className="help is-danger">{message}</div>;

    const inputElement = useRef<HTMLInputElement>(null);
// @ts-ignore


    return (

        <div className={   visible ? "Register-row" : "hidden"}>


            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Basisdaten
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                         data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <form className="Register-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="Register-row">
                                    <div className="Register-row">
                                        <label className="Register-label" >Name</label>

                                        <input className="input"   {...register('name', {required: true})}  />
                                    </div>
                                    <div className="Register-row">
                                        <label className="Register-label" >ValidFrom</label>
                                        <input className="input" {...register('validFrom', {required: true})} />
                                    </div>
                                    <div className="Register-row">
                                        <label className="Register-label" >ValidUntil</label>
                                        <input className="input" {...register('validUntil', {required: true})}  />
                                    </div>
                                    <div className="Register-row">
                                        <label className="Register-label" >KartenTyp</label>
                                        <input className="input"   {...register('kartenTyp', {required: true})}  />
                                    </div>
                                    <div  className="Register-col hidden">
                                        <input className="hidden" {...register('id', {required: true})}  />
                                        <input type={"submit"} ref={inputElement}/>
                                    </div>
                                    <div>
                                        <Fab color="primary" size="small" aria-label="add"
                                             onClick={(e)=> {
                                                 inputElement.current?.click();
                                             }}  >
                                            <DoneIcon />
                                        </Fab>
                                        <Fab color="primary" size="small" aria-label="add"
                                             onClick={(e)=> {
                                                 if(saveDto)
                                                     saveDto(undefined)
                                             }}  >
                                            <Cancel />
                                        </Fab>

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            Positionen
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo"
                         data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <SpeisekartePositionManagerComponent
                                speisekarteId={dto?.id === undefined ? "": dto.id }
                                list={
                                dto?.positionen === undefined ? [] : dto?.positionen
                            }
                                save={(o) => setPositionList(o)} />
                        </div>
                    </div>
                </div>


            </div>
        </div>

    );

}