import React, {useState} from "react";
import {Rezept} from "../../Model/Rezept";
import {AddIcon} from "../Icons/AddIcon";
import {EditIcon} from "../Icons/EditIcon";
import {RezeptDto} from "../Dto/RezeptDto";
import {Adresse} from "../../Model/Adresse";
import {v4 as uuidv4} from "uuid";
import {Fab} from "@mui/material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {AdresseComponentModel} from "../Adresssen/AdresseComponentModel";
import {RezeptItemShow} from "./RezeptItemShow";


interface RezeptListeComponentModelProps {
    rezeptArray:RezeptDto[],
    addRezept?:  (a: RezeptDto) => void,
    editRezept?: (a: RezeptDto) => void,
    showButtons: boolean
}
export function RezeptListeComponentModel(props:RezeptListeComponentModelProps) {

    const {rezeptArray, addRezept, editRezept, showButtons } = props
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [visible, setVisible] = useState(showButtons);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    function newObject() {
        let a= new RezeptDto(uuidv4(),
        "",
        "",
        12,
        undefined);
        return a;
    }

    let idx:number = 0


    return (
        <div>
            <div className={   visible || showButtons ? "Register-row-container " : "hidden"}>
                <div className="fab-button">
                    <Fab  color="primary" size="small" aria-label="add"
                          onClick={(e)=> {
                              if (addRezept) {
                                  addRezept(newObject())
                                  setVisible(false);
                              }
                          }}  >
                        <AddIcon />
                    </Fab>
                </div>
                <div className="fab-button">

                    <Fab color="secondary" size="small" aria-label="edit"
                         onClick={(e)=> {
                             console.log(("Click"))
                             if (editRezept) {
                                 editRezept(rezeptArray[selectedIndex-1])
                                 setVisible(false);
                             }
                         }}>
                        <EditIcon />
                    </Fab>
                </div>
            </div>
            <div className="Register-col" >
                <List sx={{ maxHeight: 420, overflow: 'auto'}} component="div" aria-label="main mailbox folders"  >

                    {

                        rezeptArray.reverse().map(adr =>
                        {
                            idx = idx +1
                            return (
                                <ListItemButton
                                    className={selectedIndex === idx ? ".selected" : ""}
                                    key={idx}
                                    selected={selectedIndex === idx}
                                    onClick={(event) => {
                                        const i = rezeptArray.map(x => x.id).indexOf(adr.id);
                                        handleListItemClick(event, i+1);
                                        console.log("idx: " + idx + "  i: " + i + '---' + adr.id)
                                    }
                                    }
                                >
                                    <RezeptItemShow rezept={adr}/>
                                </ListItemButton>

                            )
                        })
                    }
                </List>
            </div>
        </div>
    );
}