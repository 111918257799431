

export function  getString (s:string | undefined) {
    if(s === undefined)
    {
        return ""
    }
    return s
}

export function  getNumber (s:number | undefined) {
    if(s === undefined)
    {
        return 0
    }
    return s
}
export const formatter = new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export  function  getAxiosHeader() {
    const token =localStorage.getItem('userToken')
    if(token === null) {
        return  {
            headers: {
                "Content-Type":"application/json"
            }
        }
    }
    else {
        return  {
            headers: {

                Authorization: `Bearer ${token}`,
                    "Content-Type":"application/json"
            }
        }
    }

}