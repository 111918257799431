import React, {useState} from "react";

import {AdresseComponentModel} from "./AdresseComponentModel";
import {Adresse} from "../../Model/Adresse";
import PropTypes from "prop-types";
import {Adressenliste, IAdressenliste} from "../../Model/AdressenListe";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import {AddIcon} from "../Icons/AddIcon";
import {EditIcon} from "../Icons/EditIcon";
import { v4 as uuidv4 } from 'uuid';
import {useForceUpdate} from "../Helper/ForceUpdate";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {inspect} from "util";
import {Fab} from "@mui/material";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';



export{};


interface AdresseListeComponentModelProps {
    addresslist:IAdressenliste,
    lieferAdresseId?: string
    addAddress?:  (a: Adresse) => void,
    editAddress?: (a: Adresse) => void,
    setLieferAddress: (a:Adresse) =>void,
    showButtons: boolean
}
export const AdressenListeComponentModel: React.FC<AdresseListeComponentModelProps> = (props ) => {

    const {addresslist, addAddress , editAddress,setLieferAddress, showButtons } = props;
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [visible, setVisible] = useState(showButtons);
/*    const OnClickAdd =(

    ) => {

    };*/
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };


    function newAddress() {
        let a= new Adresse(uuidv4())

        a.strasse = "";
        a.ort = "";
        a.postleitzahl = undefined;
        a.land = "CH";
        return a;
    }

    let idx:number = 0
    console.log("AddressListComponentModel la: " + addresslist.lieferAdresse?.id)
    return (
        <div>
            <div className={   visible || showButtons ? "Register-row-container " : "hidden"}>
                <div className="fab-button">
                    <Fab  color="primary" size="small" aria-label="add"
                         onClick={(e)=> {
                             if (addAddress) {
                                 addAddress(newAddress())
                                 setVisible(false);
                             }
                         }}  >
                        <AddIcon />
                    </Fab>
                </div>
                <div className="fab-button">
                    <Fab size="small"
                         onClick={()=> {
                             if (setLieferAddress) {
                                 setLieferAddress(addresslist.adressen[selectedIndex-1]);
                             }
                         }}>

                        <LocalShippingOutlinedIcon />
                    </Fab>
                </div>
                <div className="fab-button">
                    <Fab size="small"
                         onClick={()=> {
                         }}>

                        <AccountBalanceOutlinedIcon />
                    </Fab>
                </div>
                <div className="fab-button">

                    <Fab color="secondary" size="small" aria-label="edit"
                         onClick={(e)=> {
                             console.log(("Click"))
                             if (editAddress) {
                                 editAddress(addresslist.adressen[selectedIndex-1])
                                 setVisible(false);
                             }
                         }}>
                        <EditIcon />
                    </Fab>
                </div>
            </div>
             <div className="Register-col" >
                <List sx={{ maxHeight: 220, overflow: 'auto'}} component="div" aria-label="main mailbox folders"  >
                {

                    addresslist.adressen.reverse().map(adr =>
                {
                    idx = idx +1
                    return (
                        <ListItemButton
                            classes={selectedIndex === idx ? ".selected" : ""}
                            key={adr.id}
                            selected={selectedIndex === idx}
                            onClick={(event) => {
                                const i = addresslist.adressen.map(x => x.id ).indexOf(adr.id);
                                handleListItemClick(event, i+1);
                                console.log("idx: " + idx + "  i: " + i + '---' + adr.id)
                            }
                        }
                        >
                        <AdresseComponentModel
                            adresse ={adr}
                            lieferAdresseId={addresslist.lieferAdresse?.id}



                            />
                        </ListItemButton>

                       )
                })
            }
                </List>
             </div>
        </div>
    );
}
