import {useDispatch, useSelector} from "react-redux";
import {redirect, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import React from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {IState, logout} from "../../features/auth/authSlice";


export function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading , error, success,userInfo}   =
        useSelector<any,IState>((state) => state.auth)

    const onClick = () => {
        localStorage.clear()
        dispatch(logout() )

    };
    return (
        <div>
            <div>Sie sind angemeldet als {userInfo?.userid}</div>
            <div>Zum Abmelden klicken Sie bitte die Schaltfläche:</div>
            <div>
                <Button onClick={onClick}>Abmelden</Button>
            </div>
        </div>
    );
}