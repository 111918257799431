import {IRegister, IRegisterModel, Register, RegisterModel} from "../../Model/Register";
import {Kunde} from "../../Model/Kunde";
import {Adresse} from "../../Model/Adresse";
import './RegisterClient.css'
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import { uuid } from 'uuidv4';
import {useNavigate} from "react-router-dom";
import {Rezept} from "../../Model/Rezept";
import {ITestModel, TestModel} from "../../Model/TestModel";
import './RegisterClient.css'
import {ILoginDto} from "../Dto/ILoginDto";
import '../RestApi/RegisterApiService'
import {Login} from "../RestApi/RegisterApiService";

import jwt from 'jwt-decode'
import {useDispatch, useSelector} from "react-redux";
import {userLogin} from "../../features/auth/authActions";
import {AnyAction} from "@reduxjs/toolkit";
import {IState} from "../../features/auth/authSlice";
export function RegisterModelComponent() {

    const {userInfo}   =
        useSelector<any,IState>((state) => state.auth)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    /*
    let kundeModel = new Kunde(uuid())

        let adresseModel = new Adresse(uuid());

        kundeModel.adressen.push(adresseModel);
            kundeModel.userid = "Hugo";
            adresseModel.strasse="Hübelweg 12";
            adresseModel.ort = "Bern";
            adresseModel.postleitzahl=3001;
            adresseModel.land="CH";

    let registerModel = new Register(kundeModel, undefined, undefined);
    */


    const [userid, setUserid] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        if (userInfo) {
            navigate('/rezept')
        }
    }, [navigate, userInfo])
    const {
        register
        , setError
        , handleSubmit
        , formState: {errors}
    } = useForm({
        defaultValues: {
            user: userid,
            password: password
        }
    });
    const onSubmit = (data: any) => {
        const t = data as ILoginDto;
        /*
        setData(new Register(data.m.kunde, data.m.adresse, data.m.firma));
        */

        console.log(t);
        /*
        authenticateUser(t);
        */

        dispatch(userLogin(t) as unknown as AnyAction)
    }

    const authenticateUser = (data:ILoginDto) => {
        /*Login2(data);
        Test(data);*/

                // eslint-disable-next-line no-undef

                Login(data)
                    .then((response: any) => {


                        console.log(response.data);
                        const token = jwt(response.data);


                    })
                    .catch((e: Error) => {
                        console.log(e);
                    });

            };




    const chu = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setIsValid(value.length > 0 && userid?.length !== undefined &&  userid?.length > 0)
        setUserid(value);
    };

    const chp = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setIsValid(value.length > 0 && password?.length !== undefined &&  password?.length > 0)
        setPassword(value)
    };


    const handleClick = () => navigate('/neukunde');

    // @ts-ignore
    let div = <div>
        <p className="Register-title">Anmeldung</p>

        <form className="Register-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="Register-row">
                <div className="Register-col">
                    <label className="Register-label">UserId</label>
                    <input type="text" {...register('user', {required: true})}
                           onChange={chu}
                    />
                </div>
                <div className="Register-col">
                    <label className="Register-label">Password</label>
                    <input type="text" {...register('password', {required: true})}
                           onChange={chp}
                    />
                </div>

                <div  className="Register-col ">
                    <div className="Register-row-container just-even">
                    <input type={"submit"} disabled={!isValid}/>
                    <input type={"button"} onClick={handleClick} value="Als Neukunde registrieren" />
                    </div>
                </div>
            </div>
        </form>
    </div>;
    return div;
}