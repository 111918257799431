import { createSlice } from '@reduxjs/toolkit'
import { registerUser, userLogin } from './authActions'
import {IUserInfoDto} from "../../Content/Dto/IUserInfoDto";

export interface IState {
    loading: boolean
    userInfo: IUserInfoDto |null
    userToken: string|null
    error: string |null
    success: boolean
}

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const initialState: IState = {
    loading: false,
    userInfo: null,
    userToken:null,
    error: null,
    success: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {


        logout: (state) => {
            localStorage.removeItem('userToken') // delete token from storage
            state.loading = false
            state.userInfo = null
            state.userToken = null
            state.error = null
        },
        setCredentials: (state, { payload }) => {
            state.userInfo = payload
        },
    },
    extraReducers: {
        // login user
        [userLogin.pending.toString()]: (state) => {
            state.loading = true
            state.error = null

        },
        [userLogin.fulfilled.toString()]: (state, { payload }) => {
            const pl = payload /*as ILoginResponseDto*/
            console.log('fulfilled:' + pl.token)
            state.loading = false
            state.userInfo = {name: pl.UserName, userid:pl.userName, email:pl.email, roles: Object.assign([],  pl.roles)} as IUserInfoDto
            state.userToken = pl.jwt
        },
        [userLogin.rejected.toString()]: (state, { payload }) => {
            console.log('rejected:' + payload)
            state.loading = false
            state.error = payload
        },
        // register user
        [registerUser.pending.toString()]: (state) => {
            state.loading = true
            state.error = null
        },
        [registerUser.fulfilled.toString()]: (state, { payload }) => {
            state.loading = false
            state.success = true // registration successful
        },
        [registerUser.rejected.toString()]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    },
})

export const { logout, setCredentials } = authSlice.actions

export default authSlice.reducer

