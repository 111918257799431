    import { createSlice } from '@reduxjs/toolkit'
import  {confirmEmailAction} from './confirmActions'
import {IEmailConfirmationDto} from "../../Content/Dto/IEmailConfirmationDto";

export interface IStateConfirm {
    loading: boolean
    error: string |null
    success: boolean
    finished: boolean

}

const initialState: IStateConfirm = {
    loading: false,
    error: null,
    success: false,
    finished: false
}

const confirmSlice = createSlice({
    name: 'confirm',
    initialState,
    reducers: {
        confirmEmailReset: (state, { payload }) => {
            console.log("reducer confirmMail")
            state.loading = false
            state.error = null
            state.success = false
            state.finished = false


        }
    },
    extraReducers: {
        // login user
        [confirmEmailAction.pending.toString()]: (state) => {
            console.log("extrareducer confirmMail pending")
            state.loading = true
            state.error = null
        },
        [confirmEmailAction.fulfilled.toString()]: (state, { payload }) => {
            const pl = payload /*as ILoginResponseDto*/
            console.log('fulfilled:' + payload)
            state.loading = false
            state.success = true
            state.error = null
            state.finished = true


        },
        [confirmEmailAction.rejected.toString()]: (state, { payload }) => {
            console.log('rejected:' + payload)
            state.loading = false
            state.error = payload
            state.success = false
            state.finished = true
        }
    },
})

export const { confirmEmailReset } = confirmSlice.actions

export default confirmSlice.reducer
