import {Adresse} from "../../Model/Adresse";
import React, {useState} from "react";

interface AdressenViewProps {
    address:Adresse,
    label:string
}
export function AdressenView(props:AdressenViewProps) {
    const {address, label} = props;
    const [addressview, setAddress] = useState<string>(
        address.strasse
        + ' ' + address.land
        + ' ' + address.postleitzahl
        + ' ' + address.ort
    );
    return (
        <div>
            <label className="Register-label">{label}</label>
            <div className="lieferadresse">
                {         address.strasse
                    + ' ' + address.land
                    + ' ' + address.postleitzahl
                    + ' ' + address.ort
                }
            </div>

        </div>

);
}