import React from "react";
import {useEffect, useState} from "react";
import configdata from '../../config.json';
import {GuestBookDto} from "../Dto/GuestBookDto";
import http, {httpmini} from "../../http-common";
import {AxiosResponse} from "axios";
import {getAxiosHeader} from "../Helper/helpers";
import {GuestBookActivateDto} from "../Dto/GuestBookActivateDto";

const defaultPosts:GuestBookDto[] = [];


export function fetchGuestBookList(
    setData:(data:GuestBookDto[])=>void,
    setMessage:(s:string)=>void
) {

    httpmini.get("/api/GuestBooks",

        getAxiosHeader()
    )
        // fetch(  configdata.SERVER_URL + 'api/guestBook')
        .then((response) => response.data)
        .then((data:GuestBookDto[]) => {
            console.log(data);
            setData(data);
        })
        .catch((err) => {
            console.log(err.message);
            setMessage(err.message)
        });

}


export function fetchLockedGuestBookList(
    setData:(data:GuestBookDto[])=>void,
    setMessage:(s:string)=>void
) {

    httpmini.get("/api/GuestBooks/locked",

        getAxiosHeader()
    )
        // fetch(  configdata.SERVER_URL + 'api/guestBook')
        .then((response) => response.data)
        .then((data:GuestBookDto[]) => {
            console.log(data);
            setData(data);
        })
        .catch((err) => {
            console.log(err.message);
            setMessage(err.message)
        });

}

export function saveGuestBook(
    data:GuestBookDto,
    setData:(data:GuestBookDto)=>void,
    setMessage:(s:string)=>void
)
{
    const token =localStorage.getItem('userToken')

    httpmini.post<GuestBookDto,AxiosResponse>("/api/GuestBooks",
        data,
        getAxiosHeader()

    ).then((x) => {
            setData(x.data)
            setMessage("Ihe Beitrag ist gespeichert, wird geprüft und ist danach sichtbar.")
        }
        ,
        (err)=>{
            if(err?.message !== undefined){
                setMessage(err.message())
                return
            }
            if(err?.data?.message !== undefined){
                setMessage(err.data.message())
                return
            }
            setMessage("Es ist ein Fehler beim Speichern aufgetreten")
        })
}


export function activateGuestBook(
    data:GuestBookActivateDto[],
    setData:(data:GuestBookDto[])=>void,
    setMessage:(s:string)=>void
)
{
    const token =localStorage.getItem('userToken')

    httpmini.post<GuestBookActivateDto,AxiosResponse>("/api/GuestBooks/activate",
        data,
        getAxiosHeader()

    ).then((x) => {
            setData(x.data)
            setMessage("Die ausgewählten Einträge wurden aktiviert/gelöscht")
        }
        ,
        (err)=>{
            if(err?.message !== undefined){
                setMessage(err.message)
                return
            }
            if(err?.data?.message !== undefined){
                setMessage(err.data.message)
                return
            }
            setMessage("Es ist ein Fehler beim Speichern aufgetreten")
        })
}

