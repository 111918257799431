import React from 'react';
/*import logo from './logo.svg';*/
import './App.css';
import {Headerfunc} from "./Header/Headerfunc";
import {StartContent} from "./Content/StartContent";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './AppSass.scss';

function App() {
  return (
    <div className="d-flex flex-column">
        <Headerfunc/>
            <div className="container-fluid">
                <StartContent  />
            </div>
    </div>
  );
}

export default App;
