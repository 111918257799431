import {SpeisekarteReadDto, SpeisekarteWriteDto} from "../Dto/SpeisekarteDto";
import React, {useState} from "react";
import {RezeptDto} from "../Dto/RezeptDto";
import {v4 as uuidv4} from "uuid";
import {Fab} from "@mui/material";
import {AddIcon} from "../Icons/AddIcon";
import {EditIcon} from "../Icons/EditIcon";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {RezeptItemShow} from "../Rezepte/RezeptItemShow";
import {SpeisekarteItemShow} from "./SpeisekarteItemShow";

interface SpeisekarteListeComponentModelProps {
    speisekarteArray:SpeisekarteReadDto[],
    addSpeisekarte:  (a: SpeisekarteWriteDto) => void,
    editSpeisekarte: (a: SpeisekarteWriteDto) => void,
    showButtons: boolean
}


export function SpeisekarteListComponentModel(props:SpeisekarteListeComponentModelProps) {

    const {speisekarteArray, addSpeisekarte, editSpeisekarte, showButtons } = props
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [visible, setVisible] = useState(showButtons);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    function newObject() {
        let a= new SpeisekarteWriteDto(uuidv4(),
            "",
            "10.07.2023",
            "15.07.2023",
            0,
            []
        );
        return a;
    }

    let idx:number = 0


    return (
        <div  className={   visible || showButtons ? "" : "hidden"}>
            <div className="Register-row-container">
                <div className="fab-button">
                    <Fab  color="primary" size="small" aria-label="add"
                          onClick={(e)=> {
                              if (addSpeisekarte) {
                                  addSpeisekarte(newObject())
                                  setVisible(false);
                              }
                          }}  >
                        <AddIcon />
                    </Fab>
                </div>
                <div className="fab-button">

                    <Fab color="secondary" size="small" aria-label="edit"
                         onClick={(e)=> {
                             console.log(("Click"))
                             if (editSpeisekarte) {
                                 editSpeisekarte(SpeisekarteWriteDto.fromDto( speisekarteArray[selectedIndex-1]))
                                 setVisible(false);
                             }
                         }}>
                        <EditIcon />
                    </Fab>
                </div>
            </div>
            <div className="Register-col" >
                <List sx={{ maxHeight: 220, overflow: 'auto'}} component="div" aria-label="main mailbox folders"  >

                    {

                        speisekarteArray.reverse().map(adr =>
                        {
                            idx = idx +1
                            return (
                                <ListItemButton
                                    className={selectedIndex === idx ? ".selected" : ""}
                                    key={idx}
                                    selected={selectedIndex === idx}
                                    onClick={(event) => {
                                        const i = speisekarteArray.map(x => x.id).indexOf(adr.id);
                                        handleListItemClick(event, i+1);
                                        console.log("idx: " + idx + "  i: " + i + '---' + adr.id)
                                    }
                                    }
                                >
                                    <SpeisekarteItemShow speisekarte={adr}/>
                                </ListItemButton>

                            )
                        })
                    }
                </List>
            </div>
        </div>

    );
}