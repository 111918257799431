import {Kunde} from "../../Model/Kunde";
import {v4} from "react-native-uuid/dist/v4";
import {Company} from "../../Model/Company";
import {Companylist} from "../../Model/CompanyList";


export function CreateCompany() {
    const company= new Company(v4().toString());
    return company;
}

export function CreateCompanyParam(name:string , description:string ) {
    const company= new Company(v4().toString());
    company.name = name;
    company.description=description;
    return company;
}

export function CreateCompanyList() {
    const cl = new Companylist();
    let company = CreateCompanyParam("Viacar AG","Hersteller von V09, V20, Aarau Bahnhofstrasse 2")
    cl.defaultCompany=company
    cl.addCompany(company)
    company = CreateCompanyParam("Treier","Baufirma in Schinznach Dorf")
    cl.addCompany(company);
    return cl;
}


