

import React, {useEffect, useState} from "react";
import {GuestBookDto} from "../Dto/GuestBookDto";
import {fetchGuestBookList, saveGuestBook} from "../RestApi/GuestBookApiService";
import {Adresse} from "../../Model/Adresse";
import {GuestBookListComponentModel} from "./GuestBookListComponentModel";
import {EditGuestBookComponentModel} from "./EditGuestBookComponentModel";

const defaultPosts:GuestBookDto[] = [];
export function GuestBookManagerComponent() {
    const [posts, setPosts]: [GuestBookDto[], (posts: GuestBookDto[]) => void] = useState(defaultPosts);
    const [message, setMessage]:[ string, (message: string) => void] = useState("");
    const [editGuestBook  , setEditGuestBook] = useState<GuestBookDto | undefined>(undefined);


    useEffect(() => {
        fetchGuestBookList(
            (data) =>{ setPosts(data) },
            (m) =>{ setMessage(m)}
        )
    },[] );




    function  addGuestBookFunc  (a:GuestBookDto)        {
        setEditGuestBook( a) ;
    }

    function saveGuestBookFunc(a:GuestBookDto|undefined) {
        if(a)        {
            const idx =(posts.map( x=> x.id ).indexOf( a.id))

            saveGuestBook(a,
                (data) =>{
                    fetchGuestBookList(
                        (data) =>{ setPosts(data) },
                        (m) =>{ setMessage(m)}
                    )

                },
                (m) =>{ setMessage(m)}
            )




        }
        setEditGuestBook( undefined) ;
    }


    return (
        <div className="container d-flex align-items-center flex-column">
            <h2>Gästebuch</h2>
            <div className="center errorMessage">{message}</div>
            <EditGuestBookComponentModel visible={ editGuestBook !== undefined} dto={editGuestBook} saveDto={saveGuestBookFunc} />
            <GuestBookListComponentModel guestBookArray={posts} showButtons={true} addGuestBook={addGuestBookFunc} />
        </div>
    );
}
