import React from "react";
import { Link } from "react-router-dom";
import Gericht1 from "./Bilder/Spaghetti.png"
import Gericht2 from "./Bilder/BratreisMitGemüseEi.jpg"

export function Home() {
    const mail = "mailto:info@chezmichellacatering.com?subject=Catering Feedback"


    return (
        <div className="inner px-2">
        <div className=" d-flex flex-column justify-content-center align-items-center object-fit-contain">
            <div className="px-sm-1 px-3 py-3">
                <h2> Willkommen bei ChezMichella</h2>
            </div>
            <div className="px-sm-1 px-3 py-3">
                Frisch zubereitete Speisen in Ihrer Nähe.
            </div>
            <div className="errorMessage">Neu: Machen Sie Ihre Feier zu einem besonderen Genuss: <Link to="/abend">Catering</Link></div>
            <div className="">Beachten Sie auch unsere <Link to="/abend">Abendkarte</Link></div>
            <div className="d-flex flex-row px-3 py-3 px-sm-1 text-wrap object-fit-contain w-75">
        Unser Ziel ist es, gutes Essen mit guter Qualität, zu fairen Preisen anzubieten.
        Außerdem möchten wir keine Lebensmittel verschwenden.
        Die Speisen werden frisch  vor Ort zubereitet, deshalb kommt es zu minimalen Wartezeiten von ca. 3-5 Minuten.
        Aber es ist die beste Möglichkeit, das Gemüse knackig , bissfest und schmackhaft zu bekommen.
            </div>
            <div className="dhome flex-row px-sm-1 px-3 py-3 justify-content-center justify-content-lg-between">
                <div className="justify-content-center">
                    <img src={Gericht1} className="product-img mx-auto rounded float-right"/>
                    <div>Spaghetti mit Cocktailtomaten</div>
                </div>
            <div  className="px-sm-1 px-3 py-3">
                <div>
                    Zubereitet wird in Take-Away Komponentenschalen mit Holzbesteck.
                    Man kann natürlich auch seine eigenen Behälter mitbringen.
                </div>
                <div className="mt-5">
                    Speisewünsche, Kritik und Feedback aller Art senden Sie bitte hier:
                    <a   href={mail}>Feedback</a>
                 </div>
                <div className=" p-5  flex-row align-items-center">
                    <div className="p-1 fw-bolder salmon">Keine Konservierungsstoffe</div>
                    <div className="p-1 fw-bolder salmon">Kein Glutamat oder ähnliches</div>
                    <div className="p-1 fw-bolder salmon">Keine Geschmacksverstärker</div>
                </div>
            </div>
                <div className="d-flex flex-row px-sm-1 px-3 py-3">
                    <div className="justify-content-center">
                        <img src={Gericht2} className="product-img mx-auto rounded float-right"/>
                        <div>Bratreis mit Gemüse und Rührei</div>
                    </div>
                </div>
            </div>
    </div>
        </div>
);
}