import React, {useEffect, useRef, useState} from "react";

import {SpeisekartePositionListComponent} from "./SpeisekartePositionListComponent";
import {EditSpeisekartePositionComponent} from "./EditSpeisekartePositionComponent";
import {fetchSpeisekarteList} from "../RestApi/SpeisekarteApiService";
import {SpeisekarteReadDto, SpeisekarteWriteDto} from "../Dto/SpeisekarteDto";
import {RezeptDto} from "../Dto/RezeptDto";
import {fetchRezeptList} from "../RestApi/RezeptApiService";
import {GruppeDto, SpeisekartePositionWriteDto} from "../Dto/SpeisekartePositionDto";
import {v4 as uuidv4} from "uuid";



export interface ISpeisekartePositionManagerComponentProps {
    speisekarteId:string
    list:SpeisekartePositionWriteDto[]
    save: (obj:SpeisekartePositionWriteDto[]) => void
    /*retFunc:()=>React.DOMElement<any, any>*/
}

export function SpeisekartePositionManagerComponent(props:ISpeisekartePositionManagerComponentProps) {
    const [rezeptList, setRezeptList]: [RezeptDto[], (posts: RezeptDto[]) => void] = useState<RezeptDto[]>([] );
    const [message, setMessage]:[ string, (message: string) => void] = useState("");
    const [positionList, setPositionList] = useState<SpeisekartePositionWriteDto[]>(props.list)
    const [editPosition, setEditPosition] = useState<SpeisekartePositionWriteDto|undefined>(undefined)

    useEffect(() => {
        fetchRezeptList(
            (data) =>{ setRezeptList(data) },
            (m) =>{ setMessage(m)}
        )
    },[] );

    useEffect(() => {
        setPositionList(props.list)
        setEditPosition(undefined)
    },[props.list] );

    function saveDtoFunc(a:SpeisekartePositionWriteDto | undefined) {
        if(a !== undefined) {
            const t = positionList.find(x=>x.id == a.id)
            const index = t === undefined ? -1 : positionList.indexOf(t);
            let ids = [...positionList];
            if(index < 0)
            {
                ids.push(a)
            }
            else
            {
                     // create the copy of state array
                ids[index] = a;
            }
            setPositionList(ids)
            props.save(ids)

        }
        setEditPosition(undefined);
/*
        setPositionList([]);
*/
    }

    const setEditPositionFunc = (item:SpeisekartePositionWriteDto ) => {
        setEditPosition(item)
    };
    const removePositionFunc = (item:SpeisekartePositionWriteDto) => {
        const tempArray=
            positionList.filter(x => x !== item)
        setPositionList(tempArray);
    };
    const addPositionFunc = () => {
        const pos = new SpeisekartePositionWriteDto(
            uuidv4(),
            props.speisekarteId,
            "",
            new GruppeDto(1,""),
            (positionList.length +1),
            0,
            ""
        )
        setEditPosition(pos)
    };
    return (
        <div className="d-flex flex-column">
            <SpeisekartePositionListComponent positionList={positionList}
                                              rezeptList={ rezeptList}
                                              addPosition={addPositionFunc}
                                              setEditPosition={setEditPositionFunc}
                                              removePosition={removePositionFunc}
                                              showButtons={editPosition === undefined}
            />
            <EditSpeisekartePositionComponent rezeptList={rezeptList}
                                              dto={editPosition}
                                              saveDto={saveDtoFunc}
                                              visible={editPosition !== undefined}
            />
        </div>
    );
}