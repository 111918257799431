import {RezeptListeComponentModel} from "./RezeptListeComponentModel";
import React, {useEffect, useState} from "react";
import {RezeptDto} from "../Dto/RezeptDto";
import {fetchRezeptList, saveRezept} from "../RestApi/RezeptApiService";
import {EditRezeptComponentModel} from "./EditRezeptComponentModel";
import {Adresse} from "../../Model/Adresse";

const defaultPosts:RezeptDto[] = [];
export function RezeptManagerComponentModel() {
    const [posts, setPosts]: [RezeptDto[], (posts: RezeptDto[]) => void] = useState(defaultPosts);
    const [message, setMessage]:[ string, (message: string) => void] = useState("");
    const [editRezept  , setEditRezept] = useState<RezeptDto | undefined>(undefined);


    useEffect(() => {
        fetchRezeptList(
            (data) =>{ setPosts(data) },
            (m) =>{ setMessage(m)}
        )
    },[] );




    function  addRezeptFunc  (a:RezeptDto)        {
        setEditRezept( a) ;
    }
    function  editRezeptFunc  (a:RezeptDto)        {
        setEditRezept( a) ;
    }

    function saveRezeptFunc(a:RezeptDto|undefined) {
        if(a)        {
            const idx =(posts.map( x=> x.id ).indexOf( a.id))

            saveRezept(a,
                (data) =>{
                    fetchRezeptList(
                        (data) =>{ setPosts(data) },
                        (m) =>{ setMessage(m)}
                    )

                },
                (m) =>{ setMessage(m)}
                )




        }
        setEditRezept( undefined) ;
    }


    return (
        <div className="posts-container">
            <h2>Rezeptliste</h2>
            <div className="center errorMessage">{message}</div>
            <EditRezeptComponentModel visible={ editRezept !== undefined} dto={editRezept} saveDto={saveRezeptFunc} />
            <RezeptListeComponentModel rezeptArray={posts} showButtons={true} addRezept={addRezeptFunc} editRezept={editRezeptFunc}/>
        </div>
    );
}