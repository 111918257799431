import React from "react";
import {useParams} from "react-router-dom";

export const EmailConfirmation = () => {
    return (
        <div>
            <h2>Registrierung Schritt 2</h2>
            <div>
              Vielen Dank für Ihr Interesse an unseren Dienstleistungen.
            </div>
            <div>
              Bitte überprüfen Sie Ihren Posteingang und Spam-Ordner und bestätigen Sie Ihre Email Adresse.
              Sobald die Email Adresse bestätigt wurde, können Sie sich anmelden und unsere Dienstleistungen nutzen.
            </div>
            <div>
              Wir wünschen guten Appetit!
            </div>

        </div>
    );
};