import React, {useState} from "react";
import {GuestBookDto} from "../Dto/GuestBookDto";
import {formatter} from "../Helper/helpers";

interface GuestBookItemShowProps {
    guestBook:GuestBookDto
}


export function GuestBookItemShow(props:GuestBookItemShowProps) {
    const { guestBook} = props
    const dt =new Date(guestBook.created)
    return (
        <div className="d-flex flex-md-row flex-column">
            <div  className="px-sm- px-md-3 py-1 py-md-3">{guestBook.name}</div>
            <div  className="px-sm-1 px-md-3 py-1 py-md-3">{guestBook.company}</div>
            <div   className="px-sm-1 px-md-3 py-1 py-md-3 flex-wrap text-wrap ">
                {guestBook.text}
                </div>
            <div  className="px-sm-1 px-md-3 py-sm-1 py-md-3" >
                {
                    (guestBook.image !== undefined  &&
                        guestBook.image !== null)  &&
                    <img width={180} src={guestBook.image} key={guestBook?.image?.substring(40,60)} />
                }
            </div>
            <div  className="px-sm-1 px-md-3 py-sm-1 py-md-3">{"Erstellt am:" + dt.toLocaleDateString("de-DE")}</div>
        </div>
    );
}
