import {SpeisekarteReadDto, SpeisekarteWriteDto} from "../Dto/SpeisekarteDto";
import {RezeptDto} from "../Dto/RezeptDto";
import http from "../../http-common";
import {getAxiosHeader} from "../Helper/helpers";
import {AxiosResponse} from "axios";

const defaultPosts:SpeisekarteReadDto[] = [];

export function fetchSpeisekarteList(
    setData:(data:SpeisekarteReadDto[])=>void,
    setMessage:(s:string)=>void
) {

    http.get("/api/speisekarte",

        getAxiosHeader()
    )
        // fetch(  configdata.SERVER_URL + 'api/speisekarte')
        .then((response) => response.data)
        .then((data:SpeisekarteReadDto[]) => {
            console.log(data);
            setData(data);
        })
        .catch((err) => {
            console.log(err.message);
            setMessage(err.message)
        });
}

export function saveSpeisekarte(
    data:SpeisekarteWriteDto,
    setData:(data:SpeisekarteReadDto)=>void,
    setMessage:(s:string)=>void
)
{
    const token =localStorage.getItem('userToken')

    http.post<SpeisekarteWriteDto,AxiosResponse>("/api/speisekarte",
        data,
        getAxiosHeader()

    ).then((x) =>{
            setData(x.data)
            setMessage('')
    }
            ,
        (err)=>{
            if(err?.message !== undefined){
                setMessage(err.message)
                return
            }
            if(err?.data?.message !== undefined){
                setMessage(err.data.message)
                return
            }
            setMessage("Es ist ein Fehler beim Speichern aufgetreten")
        })
}



