import {CompanySimpleData} from "../../Model/CompanySimpleData";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Company} from "../../Model/Company";
import {Simulate} from "react-dom/test-utils";
import cancel = Simulate.cancel;


interface EditCompanyComponentModelProps {

    editCompanyData?:Company
    saveCompanyData: (k: Company) => void
    cancel: () =>void
    visible:boolean
}
export function EditCompanyComponentModel(props:EditCompanyComponentModelProps) {
    const { editCompanyData, saveCompanyData, cancel, visible } = props
    const [editStateCompany  , setEditStateCompany] = useState<Company | undefined>(props.editCompanyData);

    const [name, setName] = useState<string | undefined>(editCompanyData?.name);
    const [description, setDescription] = useState<string| undefined>(editCompanyData?.description);

    const {
        register
        , setError
        , handleSubmit
        , formState: {errors}
        , reset
    } = useForm({
        defaultValues: {
            n: name,
            d: description
        }
    });

    const chName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setName(value);
    };
    const onSubmit = (data: any) => {
        const c = data as CompanySimpleData
        /*
        setData(new Register(data.m.kunde, data.m.adresse, data.m.firma));
        */
        if(editCompanyData){
            editCompanyData.name = data.n
            editCompanyData.description = data.d
/*
            editCompany.entityState = entityState.modified
*/
            props.saveCompanyData(editCompanyData);
            setEditStateCompany(undefined)
        }
    }


    if((editCompanyData?.id !== editStateCompany?.id) ) {
        setEditStateCompany(editCompanyData)
        reset()
    }
    if(editCompanyData && !editStateCompany) {
        setEditStateCompany(editCompanyData)
        reset( {
            n: editCompanyData?.name,
            d: editCompanyData?.description
        })
    }


    const cancelClickHandler = () => {
        if(props.cancel) {
            props.cancel()
        }
    };
    return (
        <div className={   visible ? "Register-row" : "hidden"}>
            <form className="Register-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="Register-row">
                    <div className="Register-col">
                        <label className="Register-label">Name</label>
                        <input type="text" {...register('n', {required: true})}
                               onChange={chName}
                        />
                    </div>
                    <div className="Register-col">
                        <label className="Register-label">Beschreibung</label>
                        <input type="text" {...register('d')}
                        />
                    </div>

                    <div  className="Register-col ">
                        <div className="Register-row-container just-even">
                            <input type={"submit"} disabled={name === undefined}/>
                            <button onClick={cancelClickHandler} >Abbrechen</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
}