import {Adresse} from "./Adresse";
import {IBase} from "./base";

export interface IAdressenliste {
    adressen: Adresse[],
    lieferAdresse: Adresse | undefined
}

export class Adressenliste implements IAdressenliste {
    get adressen(): Adresse[] {
        return this._adressen;
    }

    set adressen(value: Adresse[]) {
        this._adressen = value;
    }
    private _adressen: Adresse[];

    constructor() {
        this._adressen=[];
    }


    public  addAddress = (a: Adresse) =>  {
        this._adressen.push(a);
    }

    editAddress(a: Adresse): void {
        let edited=this._adressen.find(x => x.id === a.id );
        if(edited !== undefined)
        {
            let idx = this._adressen.indexOf(edited);
             this._adressen[idx]=a;
        }

    }

    lieferAdresse: Adresse | undefined;





}
