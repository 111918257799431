import React, {useState} from "react";
import {AddIcon} from "../Icons/AddIcon";
import {EditIcon} from "../Icons/EditIcon";
import {GuestBookDto} from "../Dto/GuestBookDto";
import {Adresse} from "../../Model/Adresse";
import {v4 as uuidv4} from "uuid";
import {Fab, ListItem} from "@mui/material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {AdresseComponentModel} from "../Adresssen/AdresseComponentModel";
import {GuestBookItemShow} from "./GuestBookItemShow";


interface GuestBookListComponentModelProps {
    guestBookArray:GuestBookDto[],
    addGuestBook?:  (a: GuestBookDto) => void,
    showButtons: boolean
}
export function GuestBookListComponentModel(props:GuestBookListComponentModelProps) {

    const {guestBookArray, addGuestBook,  showButtons } = props
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [visible, setVisible] = useState(showButtons);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    function newObject() {
        let a= new GuestBookDto(uuidv4(),
            "",
            undefined,
            "",
            false,
             new Date(Date.now()),
            undefined

    );
        return a;
    }

    let idx:number = 0


    return (
        <div>
            <div className={   visible || showButtons ? "Register-row-container " : "hidden"}>
                <div className="fab-button">
                    <Fab  color="primary" size="small" aria-label="add"
                          onClick={(e)=> {
                              if (addGuestBook) {
                                  addGuestBook(newObject())
                                  setVisible(false);
                              }
                          }}  >
                        <AddIcon />
                    </Fab>
                </div>
            </div>
            <div className="Register-col" >
                <List sx={{ maxHeight: 'none', overflow: 'auto'}} component="div"   >

                    { guestBookArray.length < 1  &&
                        <div>
                            <div>Es sind noch keine Beiträge im Gästebuch.</div>
                            <div>Erfassen Sie doch den Ersten!.</div>
                        </div>
                    }
                    {

                        guestBookArray.reverse().map(adr =>
                        {
                            idx = idx +1
                            return (
                                <ListItem

                                    className={selectedIndex === idx ? ".selected" : ""}
                                    key={idx}
                                    onClick={(event) => {
                                        const i = guestBookArray.map(x => x.id).indexOf(adr.id);
                                        console.log("idx: " + idx + "  i: " + i + '---' + adr.id)
                                    }
                                    }
                                >
                                    <GuestBookItemShow guestBook={adr}/>
                                </ListItem>

                            )
                        })
                    }
                </List>
            </div>
        </div>
    );
}
