import React from "react";
import {red} from "@mui/material/colors";
import Lachs from "../Bilder/Lachfilet.png"
import Hacksuppe from "../Bilder/Hackfleischsuppe.png"
import {Fleischdeklaration} from "./Fleischdeklaration";
import {Legende} from "./Legende";
export function Abend() {
    return (
        <div>
            <div className="dMittag">Montag-Freitag  Auslieferung: 18:00 - 22:00 Uhr</div>
            <div>Da frisch gekocht wird, benötigen wir ca. 2 Stunden von der Bestellung bis zur Auslieferung. </div>
            <div className="table-responsive py-3">
                <table className="table  table-hover ">
                    <tr>
                        <td  className="rezept-number d-block" >
                            <div className=" bg-transparent">21.</div>
                        </td>
                        <td  className="rezept-description">
                            <div className="fw-semibold py-0 bg-transparent">Bratreis mit Pouletstreifen</div>
                            <div className="py-0 bg-transparent">Basmatireis, Karotten, Erbsen, Frühlingszwiebeln, div. Kräuter </div>
                        </td>
                        <td  className="rezept-price bg-transparent">
                            <div  className=" bg-transparent">20 Chf</div>
                        </td>
                    </tr>
                    <tr className="alternate">
                        <td  className="rezept-number d-block" >
                            <div className=" bg-transparent">22.</div>
                        </td>
                        <td  className="rezept-description  bg-transparent">
                            <div  className="fw-semibold py-0 bg-transparent">Spaghetti mit Cocktailtomaten und Basilikum</div>
                            <div className="py-0 bg-transparent">Die Tomaten sind aus eigenem Anbau</div>
                        </td>
                        <td  className="rezept-price bg-transparent">
                            <div className=" bg-transparent"  ><del>20 Chf</del></div> <div className="text-danger  bg-transparent">15 Chf</div>
                        </td>
                    </tr>
                    <tr>
                        <td className="rezept-number d-block">
                            <div className=" bg-transparent">23.</div>
                        </td>
                        <td  className="rezept-description  bg-transparent">
                            <div className="fw-semibold py-0 bg-transparent">Poulet Geschnetzeltes mit Bandnudeln</div>
                            <div className="py-0 bg-transparent">und Gurkensalat</div>
                        </td>
                        <td  className="rezept-price bg-transparent">
                            <div className=" bg-transparent">22 Chf</div>
                        </td>
                    </tr>
                    <tr className="alternate">
                        <td className="rezept-number d-block">
                            <div className=" bg-transparent">24.</div>
                        </td>
                        <td  className="rezept-description bg-transparent">
                            <div className="d-flex flex-row bg-transparent">
                                <div className="w-75 bg-transparent">
                                    <div className="fw-semibold py-0 bg-transparent">Lachsfilet mit Zitronensahnesauce</div>
                                    <div className="py-0 bg-transparent">auf Basmatireis  1), 2)</div>
                                </div>
                                <img src={Lachs} className="product-img mx-auto rounded float-right  bg-transparent"/>
                            </div>
                        </td>
                        <td  className="rezept-price bg-transparent">
                            <div className=" bg-transparent">
                                <div ><del >25 Chf</del></div> <div className="text-danger  bg-transparent">21 Chf</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="rezept-number d-block">
                            <div className=" bg-transparent">25.</div>
                        </td>
                        <td  className="rezept-description bg-transparent">
                            <div className="fw-semibold py-0 bg-transparent">Gemüse-Frikadelle mit Ofen Kartoffeln</div>
                            <div className="py-0 bg-transparent">und Gurkensalat</div>
                        </td>
                        <td  className="rezept-price">
                            <div className=" bg-transparent">22 Chf</div>
                        </td>
                    </tr>
                    <tr className="alternate">
                        <td className="rezept-number d-block">
                            <div className=" bg-transparent">26.</div>
                        </td>
                        <td  className="rezept-description bg-transparent">
                            <div className="d-flex flex-row bg-transparent">
                                <div className="w-75 bg-transparent">
                                    <div className="fw-semibold py-0 bg-transparent">Michellas Spezialität: Hackfleischsuppe</div>
                                    <div className="py-0 bg-transparent">Schweizweit einzigartig. Rinderhack, Rahm, Frischkäse, Porree,</div>
                                    <div className="py-0 bg-transparent">franz. Baguette 1), 3)</div>
                                </div>
                                <img src={Hacksuppe} className="product-img mx-auto rounded float-right bg-transparent"/>
                            </div>
                        </td>
                        <td  className="rezept-price bg-transparent">
                            <div className=" bg-transparent">
                                <div className=" bg-transparent">22 Chf</div>
                            </div>
                        </td>
                    </tr>

                </table>
            </div>
            <Fleischdeklaration/>
            <Legende/>
        </div>
    );
}