import {Base} from "./base";
import {Adressenliste} from "./AdressenListe";
import {Adresse} from "./Adresse";
import {Kundenliste} from "./KundenListe";
import {Kunde} from "./Kunde";

export class Company extends Base {

    private _name: string | undefined;
    private _description: string | undefined;
    private _adressen: Adressenliste;
    private _mitarbeiterliste: Kundenliste;
    private _defaultAdresse: Adresse | undefined;

    get defaultAdresse(): Adresse|undefined {
        return this._defaultAdresse;
    }

    set defaultAdresse(value: Adresse |undefined) {
        this._defaultAdresse = value;
    }
    get mitarbeiterliste(): Kundenliste {
        return this._mitarbeiterliste;
    }

    set mitarbeiterliste(value: Kundenliste) {
        this._mitarbeiterliste = value;
    }

    constructor(id: string| undefined) {
        super(id);
        this._adressen = new Adressenliste();
        this._mitarbeiterliste = new Kundenliste();
    }
    get adressen(): Adressenliste {
        return this._adressen;
    }

    set adressen(value: Adressenliste) {
        this._adressen = value;
    }

    get description(): string | undefined {
        return this._description;
    }

    set description(value: string | undefined) {
        this._description = value;
    }

    get name(): string | undefined {
        return this._name;
    }

    set name(value: string | undefined) {
        this._name = value;
    }


    public AddAdresse(adresse: Adresse) {
        this._adressen.adressen.push(adresse);
    }

    public AddMitarbeiter(mitarbeiter: Kunde) {
        this._mitarbeiterliste.addKunde(mitarbeiter);
    }
}