import axios, {AxiosResponse} from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import configdata from "../../config.json";
import {ILoginDto} from "../../Content/Dto/ILoginDto";
import http from "../../http-common";
import jwt_decode from "jwt-decode";
import {IJwtDto} from "../../Content/Dto/IJwtDto";
import {IRegisterDto} from "../../Content/Dto/IRegisterDto";
import {ILoginResponseDto} from "../../Content/Dto/LoginResponseDto";


/*const backendURL =
    process.env.NODE_ENV !== 'production'
        ? 'http://127.0.0.1:5000'
        : process.env.REACT_APP_SERVER_URL*/

export const userLogin = createAsyncThunk(
    'auth/login',
    async (data: ILoginDto, { rejectWithValue }) => {
        try {
           var response = await http.post<ILoginDto,AxiosResponse<ILoginResponseDto>>("/Login", data);
         /*   const token = jwt_decode(response.token);

            // store user's token in local storage*/
            console.log('nach await token got:' + response.data.jwt)
            localStorage.setItem('userToken',response.data.jwt)

            return response.data
        } catch (error) {

            // @ts-ignore
            let message =error.message;
            // return custom error message from API if any
            // @ts-ignore
            if (error.response && error.response.data.message) {
                // @ts-ignore
                return rejectWithValue(error.response.data.message)
            } else {
                // @ts-ignore
                return rejectWithValue(error.message)
            }
        }
    }
)

export const registerUser = createAsyncThunk(
    'auth/register',
    async (data:IRegisterDto, { rejectWithValue }) => {
        try {

            const s = JSON.stringify(data)

            var response =
                await http.post<IRegisterDto,AxiosResponse>("/api/register/register", data);


        } catch (error) {
            // @ts-ignore
            let message =error.message;
            // @ts-ignore
            if (error.response && error.response.data) {
                // @ts-ignore
                message = error.response.data.message
                // @ts-ignore
                const sError = error.response.data.toString()
                switch(sError) {
                    case "UserName":
                        message = "UserId bereits vorhanden, bitte wählen Sie eine andere."
                        break
                    case "Email":
                        message = "Email bereits vorhanden, bitte wählen Sie eine andere."
                        break
                    case "Exception":
                        message = "Ein schwerer Fehler im Server ist aufgetreten. Bitte versuchen Sie es später noch einmal."
                        break
                    default:
                        // @ts-ignore
                        message = error.message
                        break
                }
                return rejectWithValue(message)
            } else {
                // @ts-ignore
                return rejectWithValue(error.message)
            }
        }
    }
)
