import React from "react";

export function BestellInfo() {









/*
    SendeMail(
        'user@domain.com',
        'We need your feedback',
        'UserName, we need 2 minutes of your time to fill this quick survey [link]'
     ).then(() => {
        console.log('Your message was successfully sent!');
    });
*/
 const mail = "mailto:" + process.env.REACT_APP_MAIL_CATERING + "?subject=Catering Bestellung"
        + "&body=Ihr-Name, Lieferdatum: tt.mm.jjjj, Produkt (Variante), Anzahl, Sonstige Hinweise"
    return (
        <div className="d-flex">
            <div className="Register-row">
                <div className="Register-col">
                 <h2>Information Bestellvorgang</h2>
                </div>
            <div  className="Register-row">
                Sie können per Telefon oder Mail bestellen.
                In jedem Fall erhalten Sie ein Bestätigungsmail zur Prüfung, ob die Bestellung korrekt angekommen ist.

            </div>
            <div className="Register-row">
                Kontaktmöglichkeiten:
                <ul>
                    <li>Telefon: +41 56 530 01 44</li>
                    <li>Mail: <a href={mail}>Bestellen</a></li>
                </ul>
            </div>
            <div  className="Register-row">
                Zahlungsmöglichkeiten:
                <ul>
                    <li>Senden an TWINT:  +41 79 291 16 91</li>
                    <li>Bar bei Lieferung</li>
                </ul>
            </div>
                <div className="Register-row">
                    Lieferkosten:
                    <ul>

                    </ul>
                    <li>Nahbereich Brugg, Lenzburg, Lupfig, Seon, Aarau: kostenfrei</li>
                    <li>Bereich Frick, Baden, Wohlen, Oftringen : 7 Chf.
                        Ab einem Auftragswert von 50 Chf ist die Lieferung auch hier kostenfrei.
                    </li>
                    <li> Bereich Zürich, Solothurn, Sursee : 14 Chf.
                        Ab einem Auftragswert von 80 Chf ist auch hier die Lieferung kostenfrei.
                    </li>
                </div>
        </div>
        </div>
    );
}