import React, {useCallback, useEffect, useRef, useState} from "react";
import {RezeptDto} from "../Dto/RezeptDto";
import {GruppeDto, SpeisekartePositionWriteDto} from "../Dto/SpeisekartePositionDto";
import {Fab} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {Cancel} from "@mui/icons-material";
import {useForm} from "react-hook-form";
import {SpeisekarteReadDto} from "../Dto/SpeisekarteDto";
import {RezeptItemShow} from "../Rezepte/RezeptItemShow";
import {fetchSpeisekarteList} from "../RestApi/SpeisekarteApiService";

export interface IEditSpeisekartePositionComponentProps {
    rezeptList:RezeptDto[]
    dto:SpeisekartePositionWriteDto | undefined
    saveDto?: (obj:SpeisekartePositionWriteDto | undefined) => void
    visible: boolean


}

export function EditSpeisekartePositionComponent(props:IEditSpeisekartePositionComponentProps) {
    const {rezeptList, dto,saveDto, visible } = props
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [selectedItem, setSelectedItem]=useState<RezeptDto|undefined>()
    const [dtoState, setDtoState] =
        useState<SpeisekartePositionWriteDto|undefined>(props.dto)


    const setSelected = (number: number) => {
        return setSelectedIndex(number);
    };

    useEffect(() => {
        if(dto !== undefined) {
            const rz = rezeptList.find(x=>x.id ===dto.rezeptId)
            if(rz !== undefined) {
                const idx = rezeptList.indexOf(rz)
                setSelected(idx)
            }
        }
    },[dto] );



    const initialValues = {
        id:dto?.id,
        speisekarteId: dto?.speisekarteId,
        preis:dto?.preis,
        order: dto?.order,
        beschreibung: dto?.beschreibung,
        gruppenOrder: dto?.gruppe?.gruppenOrder,
        gruppenBezeichnung : dto?.gruppe?.bezeichnung,
        rezeptId:dto?.rezeptId
    }


    const {
        register
        , setError
        , handleSubmit
        , formState: {errors}
        ,reset
        ,setValue
    } = useForm({
        defaultValues: initialValues
    });


    const onAClick = (item: RezeptDto, idx:number) => {
        // @ts-ignore
        setSelectedItem(item)
        setSelectedIndex(idx)
        setValue('preis',item.mindestpreis)
        setValue('rezeptId', item.id)
    };



    if((dto?.id !== dtoState?.id) ) {
        setDtoState(dto)
        reset()
    }
    if (dto) {
        if (!dtoState) {
            setDtoState(dto)
            // @ts-ignore
            reset({
                id: dto?.id,
                speisekarteId: dto?.speisekarteId,
                preis:dto?.preis,
                order: dto?.order,
                beschreibung: dto?.beschreibung,
                gruppenOrder: dto?.gruppe?.gruppenOrder,
                gruppenBezeichnung : dto?.gruppe?.bezeichnung,
                rezeptId:dto?.rezeptId
            })
        }
    }

    const onSubmit = (data: any) => {
        console.log("Submitted")

        let temp:SpeisekartePositionWriteDto ={
            id:data.id,
            speisekarteId:data.speisekarteId,
            preis:data.preis,
            order:data.order,
            beschreibung:data.beschreibung,
            gruppe:new GruppeDto(data.gruppenOrder, data.gruppenBezeichnung),
            rezeptId:data.rezeptId
        }
        if (saveDto){
            saveDto(temp);
        }
    }

    const renderError = (message:string) => <div className="help is-danger">{message}</div>;

    const inputElement = useRef<HTMLInputElement>(null);
    const inputElementPreis = useRef<HTMLInputElement>(null);


    let idx:number = -1

    return (
        <div className={   visible ? "" : "hidden"} >
            <h3>Edit Position</h3>
            <div className="container d-flex flex-row bg-light">
            <div className="w-50" >
                <form className="Register-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="Register-row">
                        <div className="hidden"> <input {...register('speisekarteId', {required: true})}/></div>
                        <div className="Register-row">
                            <label className="Register-label" >Gruppe Order</label>

                            <input className="input"   {...register('gruppenOrder', {required: true})}  />
                        </div>
                        <div className="Register-row">
                            <label className="Register-label" >Gruppe Bezeichnung</label>

                            <input className="input"   {...register('gruppenBezeichnung', {required: true})}  />
                        </div>
                        <div className="Register-row">
                            <label className="Register-label" >Beschreibung</label>

                            <input className="input"   {...register('beschreibung', {required: true})}  />
                        </div>
                        <div className="Register-row">
                            <label className="Register-label" >Preis</label>
                            <input className="input"

                                   {...register('preis', {required: true})}
                            />
                        </div>
                        <div className="Register-row">
                            <label className="Register-label" >Order</label>
                            <input className="input" {...register('order', {required: true})}  />
                        </div>
                        <div  className="d-flex">
                            {
                                selectedItem !== undefined &&
                                <RezeptItemShow rezept={selectedItem} />
                            }

                        </div>
                        <div  className="Register-col   ">
                            <div className="Register-row-container just-even">
                                <input type={"submit"} ref={inputElement}/>
                            </div>
                        </div>

                        <div>
                            <Fab color="primary" size="small" aria-label="add"
                                 onClick={(e)=> {
                                     inputElement.current?.click();
                                 }}  >
                                <DoneIcon />
                            </Fab>
                            <Fab color="primary" size="small" aria-label="add"
                                 onClick={(e)=> {
                                     if(saveDto)
                                         saveDto(undefined)
                                 }}  >
                                <Cancel />
                            </Fab>

                        </div>
                    </div>

                </form>

            </div>
            <div>
                <div className="list-group">
                    {
                        rezeptList.map(item =>
                        {
                            idx = idx +1
                            const myIdx = idx
                            return (

                                <a href="#"
                                   className= {myIdx === selectedIndex ? "list-group-item list-group-item-action active" :
                                    "list-group-item list-group-item-action"}
                                   key={myIdx}
                                   aria-current="true"
                                   onClick={(e)=> onAClick(item,myIdx)}>
                                   <div className="d-flex flex-column align-items-baseline">
                                       <div className="px-1">{item.name}</div>
                                       <div  className="px-1">{item.description}</div>
                                   </div>
                                </a>
                            )

                        })

                    }
                </div>
            </div>
            </div>
        </div>
    );
}