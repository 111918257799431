import {boolean} from "yup";

export{};


export class Anmeldedaten{
    get isRechnungsAdresseGleichLieferadresse(): boolean {
        return this._isRechnungsAdresseGleichLieferadresse;
    }

    set isRechnungsAdresseGleichLieferadresse(value: boolean) {
        this._isRechnungsAdresseGleichLieferadresse = value;
    }
    get telefon(): string {
        return this._telefon;
    }

    set telefon(value: string) {
        this._telefon = value;
    }
    get fullname(): string {
        return this._fullname;
    }

    set fullname(value: string) {
        this._fullname = value;
    }
    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }
    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }
    get userid(): string {
        return this._userid;
    }

    set userid(value: string) {
        this._userid = value;
    }
    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    private _password!: string
    private _userid!: string;
    private _email!:string;
    private _title!:string;
    private _fullname!:string;
    private _telefon!:string;
    private _isRechnungsAdresseGleichLieferadresse!:boolean
}