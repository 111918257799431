import React, {useCallback, useState, useRef, useEffect} from "react";
import {Adresse} from "../../Model/Adresse";
import {Fab} from "@mui/material";
import {AddIcon} from "../Icons/AddIcon";
import DoneIcon from "@mui/icons-material/Done";
import {Cancel} from "@mui/icons-material";
import {isNumberObject} from "util/types";
import {v4} from "react-native-uuid/dist/v4";
import {useForm} from "react-hook-form";

interface EditAddressComponentModelProps {
    adresse?:Adresse
    saveAddress?: (a: Adresse|undefined) => void
    visible:boolean
}
export function EditAddressComponentModel(props: EditAddressComponentModelProps) {
    const {adresse, saveAddress, visible} = props;
    const [strasse  , setStrasse] = useState<string | undefined>(props.adresse?.strasse);
    const [land  , setLand] = useState<string | undefined>(props.adresse?.land);
    const [postleitzahl  , setPostleitzahl] = useState<number | undefined>(props.adresse?.postleitzahl);
    const [ort  , setOrt] = useState<string | undefined>(props.adresse?.ort);
    const [addressState, setAddressState] = useState<Adresse|undefined>();

    const GetAddress=() => {
        const adr = props.adresse === undefined ? new Adresse(v4().toString()) : props.adresse;
        adr.strasse=strasse;
        adr.postleitzahl=postleitzahl;
        adr.ort=ort;
        adr.land=land;
        return adr;
    };

    const {
        register
        , setError
        , handleSubmit
        , formState: {errors}
        ,reset
    } = useForm({
        defaultValues: {
            s: props?.adresse?.strasse,
            l: props?.adresse?.land,
            p: props?.adresse?.postleitzahl,
            o: props?.adresse?.ort
        }
    });


    if((adresse?.id !== addressState?.id) ) {
        setAddressState(adresse)
        reset()
    }
    if(adresse && !addressState) {
        setAddressState(adresse)
        reset( {
            s: adresse?.strasse,
            l: adresse?.land,
            p: adresse?.postleitzahl,
            o: adresse?.ort
        })
    }


    const onSubmit = (data: any) => {
        /*
        setData(new Register(data.m.kunde, data.m.adresse, data.m.firma));
        */
        let temp = GetAddress();
        temp.land = data.l;
        temp.ort = data.o;
        temp.strasse = data.s;
        temp.postleitzahl = data.p;
            console.log(("Click: " + temp.strasse))
            if (saveAddress)
                saveAddress(temp);
        reset()
    }

    const inputElement = useRef<HTMLInputElement>(null);




    return (
        <div className={   visible ? "Register-row" : "hidden"}>
            <form className="Register-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="Register-row">
                <div className="Register-row">
                    <label className="Register-label" >Strasse</label>

                    <input className="input"   {...register('s', {required: true})}  />
                </div>
                <div className="Register-row">
                    <label className="Register-label" >Land</label>
                    <input className="input" {...register('l', {required: true})} />
                </div>
                <div className="Register-row">
                    <label className="Register-label" >Postleitzahl</label>
                    <input className="input" {...register('p', {required: true})}  />
                </div>
                <div className="Register-row">
                    <label className="Register-label" >Ort</label>
                    <input className="input"   {...register('o', {required: true})}  />
                </div>
                <div  className="Register-col hidden">
                    <div className="Register-row-container just-even">
                        <input type={"submit"} ref={inputElement}/>
                    </div>
                </div>
                <div>
                    <Fab color="primary" size="small" aria-label="add"
                         onClick={(e)=> {
                             inputElement.current?.click();
                         }}  >
                        <DoneIcon />
                    </Fab>
                    <Fab color="primary" size="small" aria-label="add"
                         onClick={(e)=> {
                             if(saveAddress)
                                 saveAddress(undefined)
                         }}  >
                        <Cancel />
                    </Fab>

                </div>
            </div>

            </form>

        </div>
    );
}