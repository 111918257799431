import {SpeisekarteReadDto} from "../Dto/SpeisekarteDto";
import {SpeisekarteWriteDto} from "../Dto/SpeisekarteDto";
import React, {useEffect, useState} from "react";
import {fetchSpeisekarteList, saveSpeisekarte} from "../RestApi/SpeisekarteApiService";
import {SpeisekartePositionWriteDto} from "../Dto/SpeisekartePositionDto";
import {EditSpeisekarteComponentModel} from "./EditSpeisekarteComponentModel";
import {SpeisekarteListComponentModel} from "./SpeisekarteListComponentModel";

const defaultObjects:SpeisekarteReadDto[] = [];

export function SpeisekarteManagerComponentModel() {
    const [objects, setObjects]: [SpeisekarteReadDto[], (posts: SpeisekarteReadDto[]) => void] = useState(defaultObjects);
    const [message, setMessage]:[ string, (message: string) => void] = useState("");
    const [editSpeisekarte  , setEditSpeisekarte] = useState<SpeisekarteWriteDto | undefined>(undefined);


    useEffect(() => {
        fetchSpeisekarteList(
            (data) =>{ setObjects(data) },
            (m) =>{ setMessage(m)}
        )
    },[] );




    function  addSpeisekarteFunc  (a:SpeisekarteWriteDto)        {
        setEditSpeisekarte( a) ;
    }
    function  editSpeisekarteFunc  (a:SpeisekarteWriteDto)        {
        setEditSpeisekarte( a) ;
    }

    function saveSpeisekarteFunc(a:SpeisekarteWriteDto | undefined) {
        if(a !== undefined)        {
            /*
            const idx =(objects.map( x=> x.id ).indexOf( a.id))
            */

            saveSpeisekarte(a,
                (data) =>{
                    fetchSpeisekarteList(
                        (data) =>{ setObjects(data) },
                        (m) =>{ setMessage(m)}
                    )

                },
                (m) =>{ setMessage(m)}
            )




        }
        setEditSpeisekarte( undefined) ;
    }


    return (
        <div className="posts-container">

            <h2>Speisekarten Verwaltung</h2>
            <div className="center errorMessage">{message}</div>

            <EditSpeisekarteComponentModel visible={editSpeisekarte !== undefined}
             dto={editSpeisekarte} saveDto={saveSpeisekarteFunc} />
            <SpeisekarteListComponentModel speisekarteArray={objects}

                                           showButtons={editSpeisekarte === undefined}
                                           addSpeisekarte={addSpeisekarteFunc}
                                           editSpeisekarte={editSpeisekarteFunc}/>
        </div>
    );}