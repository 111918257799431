import {Company} from "./Company";
import {IBase} from "./base";

export interface ICompanylist {
    company: Company[];
    defaultCompany: Company | undefined;
}

export class Companylist implements ICompanylist {
    get company(): Company[] {
        return this._company;
    }

    set company(value: Company[]) {
        this._company = value;
    }
    private _company: Company[];

    constructor() {
        this._company=[];
    }


    public  addCompany = (a: Company) =>  {
        this._company.push(a);
    }

    deleteCompany(a: Company): void {
        let edited=this._company.find(x => x.id === a.id );
        if(edited !== undefined)
        {
            this._company.forEach( (item, index) => {
                if(item === a) this._company.splice(index,1);
            });
        }
    }

    defaultCompany: Company | undefined;
}
