import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import {authApi} from "./Content/RestApi/RegisterApiService";
import confirmReducer from './features/Confirm/confirmSlice'
// ...

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [authApi.reducerPath]: authApi.reducer,
        confirm: confirmReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authApi.middleware),

})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch