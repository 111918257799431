import React from "react";
import http from "../../http-common";
import {ILoginDto} from "../Dto/ILoginDto";
import {IUserInfoDto} from "../Dto/IUserInfoDto";
import configdata from "../../config.json";

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {IState} from "../../features/auth/authSlice";
import {IJwtDto} from "../Dto/IJwtDto";

export function Login  (data: ILoginDto) {
    return http.post<ILoginDto,IJwtDto>("/Login", data);
}


export function Login2 (data:ILoginDto)  {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'Accept': 'application/json' },
        body: JSON.stringify(data)
    };
    fetch('https://localhost:7259/Login', requestOptions)
        .then(response => response.json())
        .then(data => console.log(data));


}


export function Test (data:ILoginDto)  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
            'Accept': 'application/json' },
    };
    fetch('https://localhost:7259/api/Register/13', requestOptions)
        .then(response =>
            response.json())
        .then(data =>
        {
            var x = data as ILoginDto;
            console.log(x)
            console.log(data)
        }

    );

}

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null;

const baseUrl =process.env["REACT_APP_SERVER_URL"]
export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as IState
            const token1 =localStorage.getItem('userToken')
            const token = state.userToken
            if (token) {
                console.log('fetchBaseQuery token found')
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
            else
            {
                console.log('fetchBaseQuery no token found')

            }
        },
    }),
    endpoints: (build) => ({
        getUserDetails: build.query({
            query: () => ({
                url: 'api/register/profile',
                method: 'GET',
            }),
        }),
    }),
})

// export react hook
export const { useGetUserDetailsQuery } = authApi
