import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {userLogin} from "../../features/auth/authActions";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {confirmEmailAction} from "../../features/Confirm/confirmActions";
import {IEmailConfirmationDto} from "../Dto/IEmailConfirmationDto";
import {IState} from "../../features/auth/authSlice";
import {IStateConfirm} from "../../features/Confirm/confirmSlice";

export const EmailConfirmationCheck = () => {
    const { param } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading, error, success, finished}   =
        useSelector<any,IStateConfirm>((state) => state.confirm)

    if (param == null) {
        return (
            <div>
                E-Mail Confirmation fehlgeschlagen.
            </div>
        );

    }

    const dto = JSON.parse(param) as IEmailConfirmationDto;

    useEffect(() => {
        console.log("EmailConfirmationCheck useEffect")

        if(!loading && !success && !finished ) {
            console.log(("dispatch confirmEmailAction"))
            dispatch(confirmEmailAction(dto ) as unknown as AnyAction)
        }
    },[loading, success, finished]);

    if(success)    {
        navigate('/emailconfirmationConfirmed')
    }


    return (
        <div>
            <div>{param}</div>
            <div>Loading : {loading}</div>
            <div>Success : {success}</div>
            <div>Error: {error}</div>
        </div>
    );
};