import '../Registration/RegisterClient.css'
import React, {useState} from "react";
import {ITestModel} from "../../Model/TestModel";
import {Kunde} from "../../Model/Kunde";
import {uuid} from "uuidv4";
import {Adresse} from "../../Model/Adresse";
import PropTypes from "prop-types";
import {AdressenListeComponentModel} from "./AdressenListeComponentModel";

export{};
interface AdresseComponentModelProps {
    adresse:Adresse
    lieferAdresseId?: string,
}

export function AdresseComponentModel (props: AdresseComponentModelProps ) {

    const {adresse , lieferAdresseId} = props;
    // eslint-disable-next-line react/prop-types
    console.log("AdresseComponentModel ad " +adresse.id?.toString());
    console.log("AdresseComponentModel la " + lieferAdresseId?.toString());
    // eslint-disable-next-line react/prop-types
    const isLieferadrese = (lieferAdresseId === adresse.id && lieferAdresseId !== undefined  );
    console.log(isLieferadrese ? "Register-row salmon" :"Register-row ");
    return (
            <div className={ isLieferadrese ? "Register-row salmon" :"Register-row " }>
                <div className="Register-row-container">
                    <div className="Register-col">{adresse.strasse} </div>
                    <div className="Register-col">{adresse.id} </div>
                </div>

                <div className="Register-row-container">
                    <div className="Register-col">
                        {adresse.land + " " + adresse.postleitzahl + " " + adresse.ort}
                    </div>
                </div>
            </div>
    );
}
AdresseComponentModel.propTypes={
    adresse:
        PropTypes.exact({
            id: PropTypes.string,
            strasse: PropTypes.string,
            postleitzahl: PropTypes.number,
            ort:  PropTypes.string,
            land: PropTypes.string
        })

}