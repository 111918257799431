import React from "react";
import BratreisEi from "../Bilder/BratreisMitGemüseEi.jpg";
import {Fleischdeklaration} from "./Fleischdeklaration";
import {Legende} from "./Legende";

export function Standorte() {
    return (
        <div className=" px-md-5">
            <div className="table-responsive py-3">
                <h4>Döttingen Surbtalstrasse 18</h4>
                <div>Mittwoch und Donnerstag</div>
                <div> 16:00 -19:00 Uhr </div>
                <div className="py-3">
                    <div className="table-responsive">
                        <h4>Speisen</h4>
                        <table className="table table-hover ">
                            <tr>
                                <td  className="rezept-description bg-transparent">
                                    <div className="d-flex flex-row bg-transparent">
                                        <div className="w-75 bg-transparent">
                                            <div  className="fw-semibold py-0 bg-transparent">Bratreis mit Gemüse und Pouletstreifen</div>
                                            <div  className="py-0  bg-transparent">Weisskohl, Karotten, PakChoi, Frühlingszwiebel,... </div>
                                        </div>
                                   </div>
                                </td>
                                <td  className="rezept-price">
                                    <div   className=" bg-transparent">12 Chf</div>
                                </td>
                            </tr>
                            <tr className="alternate">
                                 <td  className="rezept-description">
                                    <div className="fw-semibold py-0 bg-transparent">gegrillter Pouletspiess oder Bratwurst</div>
                                    <div className="py-0 bg-transparent">dazu Toastbrot</div>
                                </td>
                                <td  className="rezept-price">
                                    <div className=" bg-transparent">1 Stck 3,50 Chf</div>
                                    <div className=" bg-transparent">3 Stck 9,00 Chf</div>
                                </td>
                            </tr>
                            <tr className="">
                                <td  className="rezept-description">
                                    <div className="fw-semibold py-0 bg-transparent">kleine Portion Kürbissuppe ca.300ml</div>
                                    <div className="py-0 bg-transparent">dazu Toastbrot</div>
                                </td>
                                <td  className="rezept-price">
                                    <div className=" bg-transparent">4,00 Chf</div>
                                </td>
                            </tr>
                            <tr className="alternate">
                                <td  className="rezept-description">
                                    <div className="fw-semibold py-0 bg-transparent">normale Portion Kürbissuppe ca.450ml</div>
                                    <div className="py-0 bg-transparent">dazu Toastbrot</div>
                                </td>
                                <td  className="rezept-price">
                                    <div className=" bg-transparent">6,00 Chf</div>
                                </td>
                            </tr>
                        </table>
                        <hr/>
                        <div className="py-1">
                            <h4 className="">Getränke</h4>
                            <ul className="table-w">
                                <li><div className="d-flex flex-row"><div className="w-50">Bier alkoholfrei 0,33 l</div><div className="w-auto fw-bolder">3.50 Chf</div></div></li>
                                <li><div className="d-flex flex-row"><div className="w-50">Rivella Flasche 0,5 l</div><div className="w-auto fw-bolder">3.00 Chf</div></div></li>
                                <li><div className="d-flex flex-row"><div className="w-50">Coca Cola Flasche 0,5 l</div><div className="w-auto fw-bolder">3.00 Chf</div></div></li>
                                <li><div className="d-flex flex-row"><div className="w-50">Eistee Flasche 0,5 l</div><div className="w-auto fw-bolder">3.00 Chf</div></div></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <Fleischdeklaration/>
            <Legende/>

        </div>
            );
}