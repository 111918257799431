import React from "react";
import {  Route, Routes } from 'react-router-dom';
import {RezeptList} from "./Rezepte/Rezeptlist";
import {Home} from "./Home";
import {RegisterModelComponent} from "./Registration/RegisterModelComponent";
import {NeukundeModelComponent} from "./Kunde/NeukundeModelComponent";
import {CompanyAdministrationComponentModel} from "./Company/CompanyAdministrationComponentModel";
import {EmailConfirmation} from "./Registration/EmailConfirmation";
import {EmailConfirmationCheck} from "./Registration/EmailConfirmationCheck";
import {EmailConfirmationConfirmed} from "./Registration/EmailConfirmationConfirmed";
import {Logout} from "./Registration/Logout";
import {SpeisekarteManagerComponentModel} from "./Speisekarte/SpeisekarteManagerComponentModel";
import {Mittag} from "./Pages/Mittag";
import {BestellInfo} from "./Pages/BestellInfo";
import {GuestBookManagerComponent} from "./GuestBook/GuestBookManagerComponent";
import {GuestBookActivateComponent} from "./GuestBook/GuestBookActivateComponent";
import {Abend} from "./Pages/Abend";
import {Catering} from "./Pages/Catering";
import {Standorte} from "./Pages/Standorte";

export function StartContent() {
    return (
                <Routes>

                    <Route path="/" Component= {Home} />
                    <Route path="/register" Component= {RegisterModelComponent} />
                    <Route path = "/rezept" Component = {RezeptList} />
                    <Route path = "/neukunde" Component = {NeukundeModelComponent} />
                    <Route path = "/company" Component = {CompanyAdministrationComponentModel} />
                    <Route path=  "/emailconfirmation" Component={EmailConfirmation} />
                    <Route path=  "/emailconfirmationCheck/:param" Component={EmailConfirmationCheck} />
                    <Route path=  "/emailconfirmationConfirmed" Component={EmailConfirmationConfirmed} />
                    <Route path=  "/speisekarte" Component={SpeisekarteManagerComponentModel} />
                    <Route path= "/logout" Component={Logout} />
                    <Route path= "/standort" Component={Standorte} />
                    <Route path= "/abend" Component={Abend} />
                    <Route path= "/catering" Component={Catering} />
                    <Route path= "/bestellen" Component={BestellInfo} />
                    <Route path= "/guestbook" Component={GuestBookManagerComponent} />
                    <Route path= "/guestbookActivate" Component={GuestBookActivateComponent} />
                </Routes>
    );
}