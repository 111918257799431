import React, {useEffect, useState} from "react";

export interface IUploadRezeptProps {
    setImage: (s?:string) => void
    image?:string
}
export function UploadRezept(props:IUploadRezeptProps) {
    const {image} = props;
    const [previewImage, setPreviewImage] = useState<string | undefined>(props.image);
    const [message, setMessage] = useState<string>("");


    /*
    useEffect wird nur aufgerufen, wenn sich dto ändert und dann wird die Form neu initialisiert
 */
    useEffect(() => {
        setPreviewImage(image);

    },[image  ]);


    const handleSelectImage = (event:any): void => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            if(fileReader.result !== null)
            {
                const s=fileReader.result?.toString();
                setPreviewImage(s);
                props.setImage(s);

            }

        });
        fileReader.readAsDataURL(file);
    }

    return (
        <div className="flex col">
            <h2>Upload Rezept</h2>
            <div className="row">
                <input type="file" onChange={handleSelectImage} />
                {
                    previewImage?.toString() !== undefined  &&
                    <img src={previewImage?.toString()} alt="preview-image" key={previewImage?.substring(40,20)} />
                }
            </div>
            <div className="row">{message}</div>
        </div>
    );

}