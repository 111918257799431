import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import "./Navbar.css"
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import { useGetUserDetailsQuery } from "../Content/RestApi/RegisterApiService";
import {IState, setCredentials} from "../features/auth/authSlice";
import {IUserInfoDto} from "../Content/Dto/IUserInfoDto";
export default function Navbar(){
    // @ts-ignore
    const  {loading,userInfo } = useSelector((state)         => {
        // @ts-ignore
        return state.auth;
    })
    const dispatch = useDispatch()

    const { data, isFetching } = useGetUserDetailsQuery('userDetails', {
        pollingInterval: 900000, // 15mins
    })
    useEffect(() => {
        if (data) {
            dispatch(setCredentials(data))
        }
    }, [data, dispatch])

    let roles=[]
    if(userInfo !== undefined && userInfo !== null){
        roles = Object.assign([],  userInfo.roles)
    }
    const isStatic = process.env["REACT_APP_STATIC"] === "true"

    return <nav className="nav">
        <CustomLink to="/" role="Any" roles={roles} >Home</CustomLink>
        <div className='nav-info'>
             <span>
          {isFetching
              ? `Fetching your profile...`
              : userInfo !== null
                  ? `Angemeldet als: ${userInfo?.email}`
                  : "Sie sind nicht angemeldet"}
        </span>
        </div>
        { !isStatic &&
            <div>{process.env["REACT_APP_STATIC"]}</div>

        }
        <div className='nav-info'>
            {loading ? "Daten werden vom Server gelesen": ""}
        </div>
        {
            (isStatic === undefined || isStatic) &&

            <div className='nav-group'>
                <CustomLink to="/standort" role="Any" roles={roles}   >Standorte</CustomLink>
                <CustomLink to="/abend" role="Any" roles={roles}   >Abendkarte</CustomLink>
                <CustomLink to="/catering" role="Any" roles={roles}   >Catering</CustomLink>
                <CustomLink to="/bestellen" role="Any" roles={roles}   >Bestellen</CustomLink>
                <CustomLink to="/guestbook" role="Any" roles={roles} >Gästebuch</CustomLink>

            </div>

        }

        {
            (isStatic !== undefined && !isStatic) &&

            <div id='menu' className='nav-group'>
            <CustomLink to="/speisekarte"  >Speisekarte</CustomLink>
            <ProtectedLink role="Admin" roles={userInfo !== undefined && userInfo !== null ? userInfo.roles : []} >
                <CustomLink to="/company"  >Firmen</CustomLink>
                <CustomLink to="/speisekarte"  >Speisekarte</CustomLink>
                <CustomLink to="/guestbookActivate" >Gästebuch akt.</CustomLink>
                <CustomLink to="/rezept"    >Rezepte</CustomLink>
            </ProtectedLink>
            {  (userInfo === null || userInfo  === undefined) &&
                <CustomLink to="/register" role="Any" roles={roles}  >Register</CustomLink>
            }
            {  userInfo !== null && userInfo  !== undefined &&
            <CustomLink to="/logout" role="Any" roles={roles}   >Logout</CustomLink>
            }

        </div>

        }
    </nav>
}


// @ts-ignore
function CustomLink ({ to, children , ...props}) {
    // @ts-ignore
    const path = window.location.pathname;


    return (
        <div className={path === to ? "nav-active " : "nav-element"}>
            <Link to={to} {...props} >
                  {children}
            </Link>
        </div>
    )

}
CustomLink.propTypes={
    to:PropTypes.string,
    children:PropTypes.array
}

// @ts-ignore
function ProtectedLink( { role, roles , children , ...props}) {
// @ts-ignore
 const found = roles.find((rl) => {
        return rl.toLowerCase() === role.toLowerCase();
    });
    if(role !== "Any"  && !found)
    {
        return <span></span>;
    }
    return <div>{children}</div>

}

ProtectedLink.propTypes={
    role:PropTypes.string,
    roles:PropTypes.array,
    children:PropTypes.array
}
