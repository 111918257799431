import {RezeptDto} from "./RezeptDto";

export class GruppeDto {

    constructor(gruppenOrder: number,
                bezeichnung:string) {
        this.gruppenOrder = gruppenOrder
        this.bezeichnung = bezeichnung
    }
    gruppenOrder: number
    bezeichnung:string
}
export class SpeisekartePositionWriteDto {

    constructor(
        id: string,
        speisekarteId: string,
        beschreibung: string,
        gruppe:GruppeDto,
        order:number,
        preis: number,
        rezeptId : string

    ) {
        this.id = id
        this.order=order
        this.beschreibung=beschreibung
        this.gruppe = gruppe
        this.speisekarteId=speisekarteId
        this.preis=preis
        this.rezeptId=rezeptId
    }

    id: string
    speisekarteId: string
    beschreibung: string
    gruppe: GruppeDto
    order:number
    preis: number
    rezeptId : string

}

export class SpeisekartePositionReadDto {

    constructor(
        id: string,
        speisekarteId: string,
        beschreibung: string,
        gruppe:GruppeDto,
        order:number,
        preis: number,
        rezeptId: string,
        rezept : RezeptDto

        ) {
        this.id = id
        this.order=order
        this.beschreibung=beschreibung
        this.gruppe = gruppe
        this.speisekarteId=speisekarteId
        this.preis=preis
        this.rezept=rezept
        this.rezeptId= rezeptId
    }
    id: string
    speisekarteId: string
    beschreibung: string
    gruppe: GruppeDto
    order: number
    preis: number
    rezeptId: string
    rezept : RezeptDto

}