import React from "react";
import {useEffect, useState} from "react";
import configdata from '../../config.json';
import {RezeptDto} from "../Dto/RezeptDto";
import {RezeptListeComponentModel} from "../Rezepte/RezeptListeComponentModel";
import http from "../../http-common";
import {IEmailConfirmationDto} from "../Dto/IEmailConfirmationDto";
import {AxiosResponse} from "axios";
import {getAxiosHeader} from "../Helper/helpers";

const defaultPosts:RezeptDto[] = [];


export function fetchRezeptList(
    setData:(data:RezeptDto[])=>void,
    setMessage:(s:string)=>void
) {

    http.get("/api/rezept",

        getAxiosHeader()
        )
        // fetch(  configdata.SERVER_URL + 'api/rezept')
        .then((response) => response.data)
        .then((data:RezeptDto[]) => {
            console.log(data);
            setData(data);
        })
        .catch((err) => {
            console.log(err.message);
            setMessage(err.message)
        });

}

export function saveRezept(
    data:RezeptDto,
    setData:(data:RezeptDto)=>void,
    setMessage:(s:string)=>void
)
{
    const token =localStorage.getItem('userToken')

    http.post<RezeptDto,AxiosResponse>("/api/rezept",
        data,
        getAxiosHeader()

     ).then((x) =>
        setData(x.data),
        (err)=>{
            if(err?.message !== undefined){
                setMessage(err.message())
                return
            }
            if(err?.data?.message !== undefined){
                setMessage(err.data.message())
                return
            }
            setMessage("Es ist ein Fehler beim Speichern aufgetreten")
        })
}


