import React, {useState} from "react";
import {RezeptDto} from "../Dto/RezeptDto";
import {formatter} from "../Helper/helpers";

interface RezepItemShowProps {
    rezept:RezeptDto
}


export function RezeptItemShow(props:RezepItemShowProps) {
    const { rezept} = props
    return (
            <div className="Register-col-flex">
                <div style={{width:"150px"}} className="mainx">{rezept.name}</div>
                <div style={{width:"300px"}} className="">{rezept.description}</div>
                <div  style={{width:"100px"}}  className="">{formatter.format(rezept.mindestpreis)}</div>
                <div  style={{width:"200px"}} >
                {
                    (rezept.image !== undefined  &&
                    rezept.image !== null)  &&
                    <img width={180} src={rezept.image} key={rezept?.image?.substring(40,60)} />
                }
                </div>
            </div>
    );
}