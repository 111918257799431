
export class GuestBookDto  {

    constructor(id:string,
                name:string,
                company: string |undefined,
                text:string,
                isActivated: boolean,
                created: Date,
                image?:string

    ) {
        this.id=id
        this.name = name
        this.company = company
        this.text = text
        this.isActivated = isActivated
        this.created = created
        if(image !== undefined ) {
            this.image = image
        }
    }

    id:string ;
    name:string;
    company: string |undefined;
    text:string;
    isActivated: boolean;
    created: Date;
    image?:string;

}