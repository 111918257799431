import React, {useEffect, useState} from "react";
import {Company} from "../../Model/Company";
import {Adresse} from "../../Model/Adresse";
import {CreateKunde} from "../Kunde/KundeFactory";
import {CreateCompany, CreateCompanyList} from "./CompanyFactory";
import {ICompanylist} from "../../Model/CompanyList";
import {CompanyComponentManager} from "./CompanyComponentManager";

export function CompanyAdministrationComponentModel() {
    const [companyList, setCompanyList] = useState<ICompanylist>()
    const [company, setCompamy] = useState<Company | undefined>()
    const [defaultAdresseState, setDefaultAdresseState] = useState<Adresse|undefined>();

    useEffect(() => {
        (async () => {
            setCompanyList(await  CreateCompanyList());
        })();
    }, []);


    if(companyList === undefined)    {
        return(
            <div>
                <p className="Register-title centerText">Verwaltung Firmen</p>
                <h2>Loading...</h2>
            </div>

        )
    }



    return (
        <div>
            <p className="Register-title centerText">Verwaltung Firmen</p>
            <CompanyComponentManager companyList={companyList} />
        </div>
    );
}