import React from "react";

const mail = "mailto:" + process.env.REACT_APP_MAIL_CATERING + "?subject=Catering Veranstaltung"
    + "&body=Ihr-Name, Datum der Veranstaltung: tt.mm.jjjj, Uhrzeit der Veranstaltung: hh:mm, Anzahl Personen, Telefonnummer für Rückruf, Ihre Wünsche an Speisen und Getränken."
export function Catering() {
    return (
        <div className="px-3">
            <h2>Catering für bis ca. 40 Personen</h2>
            <div className="py-1">
                Gern kümmern wir uns um den kulinarischen Teil Ihrer Veranstaltung und sorgen für das Gelingen.
            </div>
            <div className="w-75 py-1"> Wir bieten Ihnen <span className="fw-bolder">Kochen vor Ort</span> an.
                Dann werden Ihre Gäste kontinuierlich mit Fingerfood der kreolischen
            Küche verwöhnt, wie es auf Mauritius üblich ist.
                Voraussetzung ist ein Stromanschluss und Wasseranschluss am Ort der Veranstaltung.</div>
            <div className="py-1">Wir können auch eine Auswahl der Speisen unser Speisekarte zubereiten oder kalte Platten.</div>
            <div className="py-1">Getränke können auch geliefert werden, allerdings keine Spirituosen.</div>
            <div className="py-1">Eine Veranstaltung bedarf immer sorgfältiger Planung. Deshalb gibt es kein Standardangebot. Wir werden Speisen und Getränke individuell mit Ihnen abstimmen.</div>
            <div className="py-1">Als Kalkulationsgrundlage rechnen wir mit mindestens <span className="fw-bolder">40 Chf</span> pro Person nur für das Essen</div>
            <div className="py-1">
                <div className="">Kalkulationsgrundlage Getränke</div>
                <ul className="table-w">
                    <li><div className="d-flex flex-row"><div className="w-50">Rotwein Flasche 0,7 l</div><div className="w-auto fw-bolder">25 Chf</div></div></li>
                    <li><div className="d-flex flex-row"><div className="w-50">Weisswein Flasche 0,7 l</div><div className="w-auto fw-bolder">25 Chf</div></div></li>
                    <li><div className="d-flex flex-row"><div className="w-50">Feldschlösschen Flasche 0,5 l</div><div className="w-auto fw-bolder">4.00 Chf</div></div></li>
                    <li><div className="d-flex flex-row"><div className="w-50">Feldschlösschen alkoholfrei 0,33 l</div><div className="w-auto fw-bolder">3.50 Chf</div></div></li>
                    <li><div className="d-flex flex-row"><div className="w-50">Rivella Flasche 0,5 l</div><div className="w-auto fw-bolder">3.00 Chf</div></div></li>
                    <li><div className="d-flex flex-row"><div className="w-50">Coca Cola Flasche 0,5 l</div><div className="w-auto fw-bolder">3.00 Chf</div></div></li>
                </ul>
            </div>
            <div className="Register-row">
                Kontaktmöglichkeiten für Ihre Anfrage:
                <ul>
                    <li>Telefon: +41 56 530 01 44</li>
                    <li>Mail: <a href={mail}>Anfrage</a></li>
                </ul>
            </div>


        </div>
    );
}