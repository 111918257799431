import axios from "axios";

export const axiosConfigDefault={
    baseURL: process.env["REACT_APP_SERVER_URL"],
    headers: {
        "Content-type": "application/json",
        "Accept":"application/json"
    }
}

export const axiosConfigMini={
    baseURL: process.env["REACT_APP_MINI_SERVER_URL"],
    headers: {
        "Content-type": "application/json",
        "Accept":"application/json"
    }
}


export default axios.create(axiosConfigDefault);
export const httpmini = axios.create(axiosConfigMini);


