import {AdressenListeComponentModel} from "./AdressenListeComponentModel";
import React, {useState} from "react";
import {IAdressenliste} from "../../Model/AdressenListe";
import {Adresse} from "../../Model/Adresse";
import {EditAddressComponentModel} from "./EditAddressComponentModel";

interface IAdresseComponentModelProps {
    addressList:IAdressenliste,
            setLieferAddress: (a:Adresse) =>void
}
export function AddressManager(props:IAdresseComponentModelProps ) {
    const  {addressList,  setLieferAddress    } = props;
    const addressArray:Adresse[] = addressList.adressen;

    const [editAdresse  , setEditAdresse] = useState<Adresse | undefined>(undefined);


    function  addAddress1  (a:Adresse)        {
            setEditAdresse( a) ;
     }
    function  editAddress1  (a:Adresse)        {
        setEditAdresse( a) ;
    }

    const xy:IAdressenliste = props.addressList as IAdressenliste;

    function saveAddress(a:Adresse|undefined) {
        if(a)        {
            const idx =(addressArray.map( x=> x.id ).indexOf( a.id))
            if(idx === -1) {
                addressArray.push(a);
            }
            else {
                addressArray[idx] = a;
            }



        }
        setEditAdresse( undefined) ;
    }


    return (
        <div >
            <EditAddressComponentModel visible={ editAdresse !== undefined} adresse={editAdresse} saveAddress={saveAddress} />
            <AdressenListeComponentModel addresslist= {xy}
                                         addAddress={addAddress1}
                                         editAddress={editAddress1}
                                         setLieferAddress={setLieferAddress}
                                         showButtons={editAdresse === undefined}

            />
        </div>
    );
}