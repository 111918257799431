import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import {IRegister} from "../../Model/Register";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AdressenListeComponentModel} from "../Adresssen/AdressenListeComponentModel";
import {Kunde} from "../../Model/Kunde";
import { v4 as uuidv4 } from 'uuid';
import {Adresse} from "../../Model/Adresse";
import {v4} from "react-native-uuid/dist/v4";
import {AddressManager} from "../Adresssen/AddressManager";
import {IAdressenliste} from "../../Model/AdressenListe";
import {AdressenView} from "./AdressenView";
import {CreateKunde} from './KundeFactory';
import {useNavigate, useParams} from "react-router-dom";
import {useInit} from "../Helper/InitHook";
import {KundeComponentModel} from "./KundeComponentModel";
import {Anmeldedaten} from "../../Model/Anmeldedaten";
import {registerUser} from "../../features/auth/authActions";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {IRegisterDto} from "../Dto/IRegisterDto";
import {IState} from "../../features/auth/authSlice";
import {IAddressDto} from "../Dto/IAddressDto";
import {getNumber, getString} from "../Helper/helpers";
import {styled} from "@mui/system";
import {theme} from "../../CmThemeProvider";
import {lightBlue, orange} from "@mui/material/colors";

export{};


interface NeukundeModelComponentProps {
    kunde:Kunde;
}
export function NeukundeModelComponent() {

    const {loading , error, success,userInfo}   = useSelector<any,IState>((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const initialAnmeldedaten = new Anmeldedaten()
    initialAnmeldedaten.email=""
    initialAnmeldedaten.userid=""
    initialAnmeldedaten.password=""

    const [ kunde, setKunde ] = useState<Kunde>();
    const [ anmeldedaten, setAnmeldedaten ] = useState<Anmeldedaten>(
        initialAnmeldedaten
    );

    const [message, setMessage] = useState<string>();
    const [isRechnungsAdresseGleichLieferadresse, setIsRechnungsAdresseGleichLieferadresse] = useState<boolean>();
    const [lieferAdresseState, setlieferAdresseState] = useState<Adresse|undefined>();
    const [rechnungsAdresseState, setrechnungsAdresseState] = useState<Adresse|undefined>();
    const [isValid, setIsValid] = useState<boolean>(false);


    const classes = {
        root: {
            width: "100%",
            "& .Mui-expanded": {
                transform: "rotate(0deg)",
                backgroundColor: orange[200]
            },
            "& .MuiAccordionSummary-content": {
                flexGrow: 12
            },
            "& .MuiIconButton-root": {
                flexGrow: 2,
                paddingTop: 0
            }
        },
        accordion: {
            minHeight: 25, //ugly but works
            height: "100%",
            backgroundColor: orange[50],
            display: "flex",
            minWidth: "50rem",
            flexDirection: "column",
            "& .Mui-expanded": {
                transform: "rotate(0deg)",
                Height: 20
            },
            "& .MuiAccordionSummary-root": {
                transform: "rotate(0deg)",
                Height: 20,
                backgroundColor: orange[200]
            },


        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            backgroundColor: orange[200],
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary
        },
        details: {
            alignItems: "center",
            border: "2px solid rgba(0,0,0,0.1)",
            borderRadius: 4
        },
        column: {
            flexBasis: "33.33%"
        },
        helper: {
            padding: theme.spacing(1, 2)
        }
    };


    //     async und [] sind entscheidend, um die Endlosschleife durch SetKunde (state setzen in useEffect)
    //     zu verhindern


    const checkValid = () =>
    {
        if(!kunde || !kunde.lieferAdresse ||anmeldedaten.userid ==='' || anmeldedaten.password ===''){

            setIsValid(false)
        }
        else {
            setIsValid(true);
        }
    }

    useEffect(() => {
        (async () => {
            setKunde(await  CreateKunde());
            setAnmeldedaten(initialAnmeldedaten)
            checkValid()
        })();
    }, []);


    useEffect(() => {
        if (!loading && success) {
            navigate('/emailconfirmation')
        }
        if(error === null) {
           if(message !== undefined)
           {
               setMessage(undefined)
           }
        }
        else {

            setMessage(error)
        }


    }, [navigate, loading, success, error])
    if(kunde === undefined)    {
        return(
            <div>
                <p className="Register-title">Registrierung Neukunde</p>
                <h2>Loading...</h2>
            </div>

        )
    }

    /*
      Notwendig, um im AdressView eine Aktualisiserung der angezeigten Adresse zu erreichen
     */


    function saveAnmeldedaten (k: Anmeldedaten) {
        setAnmeldedaten(k);
        checkValid()
    }
    // @ts-ignore
    const addressListe: IAdressenliste =  { adressen : kunde.adressen.adressen, lieferAdresse: kunde?.lieferAdresse} ;

    const setLieferAddress = (a:Adresse) =>{
        // @ts-ignore
        kunde.lieferAdresse= a;
        addressListe.lieferAdresse = a;
        setlieferAdresseState(a);
        checkValid()
    }

    // @ts-ignore
    console.log("Kunde.lieferadresse: " + kunde?.lieferAdresse?.id)
    const onRegister = () => {
        if(lieferAdresseState !== undefined)
        {
            const ra:IAddressDto|undefined = rechnungsAdresseState === undefined ? undefined :
                {id: getString(rechnungsAdresseState.id),
                strasse:getString(rechnungsAdresseState.strasse),
                postleitzahl:getNumber(rechnungsAdresseState.postleitzahl),
                ort:getString(rechnungsAdresseState.ort),
                land:getString(rechnungsAdresseState.land)}

            const la:IAddressDto ={id: getString(lieferAdresseState.id),
                strasse:getString(lieferAdresseState.strasse),
                postleitzahl:getNumber(lieferAdresseState.postleitzahl),
                ort:getString(lieferAdresseState.ort),
                land:getString(lieferAdresseState.land)}
            const data:IRegisterDto = {
                username: anmeldedaten.userid,
                email:anmeldedaten .email,
                password:anmeldedaten.password,
                title:anmeldedaten.title,
                fullname:anmeldedaten.fullname,
                telefon:anmeldedaten.telefon,
                lieferadresse: la,
                isRechnungsAdresseGleichLieferadresse:anmeldedaten.isRechnungsAdresseGleichLieferadresse,
                rechnungsadresse:ra

            }
            console.log(data);
            dispatch(registerUser(data) as unknown as AnyAction)

        }
    };
    return (
        // @ts-ignore
         <div className={classes.root}>
            <div className="Register-title centerText">Registrierung Neukunde</div>
            <div className="errorMessage" >{error}</div>
            <div> {JSON.stringify(anmeldedaten)} </div>
            <div  className="Register-col ">
                <div className="Register-row-container just-even">
                    <input className="input" type={"button"} disabled={!isValid} value='Register' content='Speichern' onClick={onRegister} />

                </div>
            </div>

            <Accordion defaultExpanded={true} sx={classes.accordion}>
                <AccordionSummary
                    sx={classes.heading}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Kundendaten</Typography>
                </AccordionSummary>
                <AccordionDetails sx={classes.details}>
                    <Typography className="salmon">
                        <KundeComponentModel data={anmeldedaten} saveAnmeldedaten={saveAnmeldedaten}/>
                        <div className="Register-col">
                            {  lieferAdresseState !== undefined &&
                                <AdressenView address={lieferAdresseState} label="Lieferadresse"/>
                            }
                            {
                                lieferAdresseState === undefined &&
                                <div className="errorMessage centerText">Lieferadresse fehlt</div>
                            }
                        </div>

                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion  sx={classes.accordion}>
                <AccordionSummary
                    sx={classes.heading}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Adressen</Typography>
                </AccordionSummary>
                <AccordionDetails sx={classes.details}>
                    <Typography sx={{maxHeight:'300px', display: 'contents'}}>
                        <AddressManager  addressList= {addressListe}
                                        setLieferAddress={setLieferAddress}

                        />
                    </Typography>
                </AccordionDetails>

            </Accordion>

        </div>
    );
}

