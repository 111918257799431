import React, {useState} from "react";
import {Company} from "../../Model/Company";
import {AddressManager} from "../Adresssen/AddressManager";
import {EditCompanyComponentModel} from "./EditCompanyComponentModel";
import {Kunde} from "../../Model/Kunde";
import {Adresse} from "../../Model/Adresse";
import {IAdressenliste} from "../../Model/AdressenListe";
import {AdressenView} from "../Kunde/AdressenView";

interface ISingleCompanyComponentModelProps {
    className?:string |undefined
    editCompanyData?:Company
    saveCompanyData: (k: Company) => void
    cancel: () =>void
    visible:boolean
}

export function SingleCompanyComponentModel(props: ISingleCompanyComponentModelProps) {
    const { className, editCompanyData, saveCompanyData, cancel ,visible  } = props
    const [defaultAdresseState, setDefaultAdresseState] = useState<Adresse|undefined>();


    if(editCompanyData === undefined) {
        return (
            <div className={   visible ? "Register-row " + props.className : "hidden"}>
                <p className="Register-title center">Bearbeiten Firma</p>
                <h2>Loading...</h2>
            </div>

        )
    }
    // @ts-ignore
    const addressListe: IAdressenliste =  { adressen : editCompanyData.adressen.adressen,
        lieferAdresse: editCompanyData.adressen.lieferAdresse}

    const setDefaultAddressImpl = (a:Adresse) => {
        setDefaultAdresseState(a)
        editCompanyData.defaultAdresse=a

    }

    return (
        <div className={   visible ? "Register-row " + props.className : "hidden"}>

            <EditCompanyComponentModel visible={ editCompanyData !== undefined} editCompanyData={editCompanyData} saveCompanyData={saveCompanyData} cancel={cancel} />
            <div className="Register-col">
                {  defaultAdresseState !== undefined &&
                    <AdressenView address={defaultAdresseState} label="Lieferadresse"/>
                }
            </div>

            <AddressManager  addressList= {addressListe}
                             setLieferAddress={setDefaultAddressImpl}

            />
        </div>
    )
}