import React from "react";
import {Link} from "react-router-dom";

export const EmailConfirmationConfirmed = () => {
    return (
        <div>
            <h2>Registrierung erfolgreich</h2>
            <div>
                Ihre Email ist bestätigt.
                Sie können sich jetzt anmelden:
                <Link to='/register'>Anmelden</Link>
            </div>
        </div>
    );
};