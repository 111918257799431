
import {UUID} from "crypto";
import {Base, IBase} from "./base";
import {uuid} from "uuidv4";


/*
export interface IAdresse extends IBase{
    strasse: string | undefined,
    postleitzahl: number | undefined,
    ort: string | undefined,
    land: string | undefined,
}
*/

export class Adresse extends Base /*implements IAdresse*/ {

    constructor(id: string| undefined) {
        super(id === undefined ? uuid() : id)

    }
    get strasse(): string| undefined {
            return this._strasse;
    }

    set strasse(value: string| undefined) {
        this._strasse = value;
    }
    set postleitzahl(value: number| undefined) {
        this._postleitzahl = value;
    }
    get postleitzahl(): number| undefined {
        return this._postleitzahl;
    }
    get ort(): string | undefined{
        return this._ort;
    }

    set ort(value: string| undefined ) {
        this._ort = value;
    }
    get land(): string| undefined {
        return this._land;
    }

    set land(value: string | undefined) {
        this._land = value;
    }
    private _strasse: string| undefined;
    private _postleitzahl: number  | undefined;
    private _ort: string| undefined;
    private _land: string | undefined;

}