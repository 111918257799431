import logo from "../logo.svg";
import cmclogo from "../CmCLogo.svg";
import React from "react";
import './Headerfunc.css'
import Navbar from "../Helper/Navbar";
export function Headerfunc() {
    return (
        <header className=".container-fluid">
            <div className="Header-main ">
                <img src={logo} className="Header-logo" alt="logo" />
                <div className="flex-row">
                <h1 className="Header-h1">
                    Catering Service
                </h1>
                </div>
                <img src={cmclogo} className="Header-logo2" alt="logo" />
            </div>
            <Navbar/>
        </header>    );
}