import {SpeisekarteReadDto} from "../Dto/SpeisekarteDto";
import {formatter} from "../Helper/helpers";
import React from "react";



interface SpeisekarteItemShowProps {
    speisekarte: SpeisekarteReadDto
}


export function SpeisekarteItemShow(props : SpeisekarteItemShowProps) {
    const { speisekarte} = props
    return (
        <div className="Register-col-flex">
            <div style={{width:"150px"}} className="mainx">{speisekarte.name}</div>
            <div style={{width:"100px"}} className="">{speisekarte.validFrom}</div>
            <div style={{width:"100px"}} className="">{speisekarte.validUntil}</div>
            <div style={{width:"100px"}} className="">{speisekarte.kartenTyp === 0 ? 'Mittag' : 'Abend'}</div>
        </div>
    );

}