import React from "react";
export function Fleischdeklaration() {
    return (
        <div>
            <h5>Fleischdeklaration:</h5>
            <ul>
                <li>Schweiz</li>
                <li>Schwarzwald(Deutschland)</li>
            </ul>
            <h5>Fischdeklaration:</h5>
            <ul>
                <li>Norwegen(Zucht)</li>
            </ul>

        </div>
    );
}