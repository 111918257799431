import React, {useEffect, useRef, useState} from "react";
import {SpeisekartePositionWriteDto} from "../Dto/SpeisekartePositionDto";
import {RezeptDto} from "../Dto/RezeptDto";
import {Fab} from "@mui/material";
import {AddIcon} from "../Icons/AddIcon";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import {EditIcon} from "../Icons/EditIcon";
import {DeleteIconComponent} from "../Icons/DeleteIcon";


export interface ISpeisekartePositionListComponentProps {
    positionList:SpeisekartePositionWriteDto[],
    rezeptList: RezeptDto[],
    addPosition: ()=> void,
    setEditPosition : (position: SpeisekartePositionWriteDto )=> void,
    removePosition: (position: SpeisekartePositionWriteDto)=> void,
    showButtons:boolean
}
export function SpeisekartePositionListComponent(props: ISpeisekartePositionListComponentProps) {
    const {positionList, rezeptList,addPosition
        , setEditPosition,  removePosition , showButtons}=props
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [selectedItem, setSelectedItem]=useState<SpeisekartePositionWriteDto|undefined>()

    const onAClick = (item: SpeisekartePositionWriteDto, idx:number) => {
        // @ts-ignore
        setSelectedItem(item)
        setSelectedIndex(idx)
    };


    let idx:number = -1
    let lastGruppe:string = ""
    let renderGruppe:boolean = false

    return (
        <div>
            <h3>Speisekarte Positionen Liste</h3>
            <div className={ showButtons ? "container d-flex " : "hidden"}>
                <div className="fab-button">
                    <Fab  color="primary" size="small" aria-label="add"
                          onClick={(e)=> {
                              if (addPosition) {
                                  addPosition()
                              }
                          }}  >
                        <AddIcon />
                    </Fab>
                </div>
                <div className="fab-button">
                    <Fab size="small"
                         onClick={()=> {
                             if(removePosition) {
                                 removePosition(positionList[selectedIndex])
                             }
                         }}>

                        <DeleteIconComponent />
                    </Fab>
                </div>
                <div className="fab-button">

                    <Fab color="secondary" size="small" aria-label="edit"
                         onClick={(e)=> {
                             console.log(("Click"))
                             if (setEditPosition) {
                                 setEditPosition(positionList[selectedIndex])
                             }
                         }}>
                        <EditIcon />
                    </Fab>
                </div>
            </div>


            <div className="list-group">
            {
                positionList.sort((a,b)=>{
                    if(a?.gruppe?.gruppenOrder === b?.gruppe?.gruppenOrder) {
                        return a.order >= b.order ? 1 : -1
                    }
                    else {
                        return a?.gruppe?.gruppenOrder >=b?.gruppe?.gruppenOrder  ? 1 : -1
                    }

                }).map(item => {
                    idx = idx +1
                    renderGruppe = false
                    if(lastGruppe !== item?.gruppe?.bezeichnung)
                    {
                        lastGruppe = item?.gruppe?.bezeichnung
                        renderGruppe = true
                    }
                    const myIdx = idx
                    const rezept= rezeptList.find(x=> x.id === item.rezeptId)
                    return (

                        <div key={myIdx}>

                            <div>{ renderGruppe ? lastGruppe : ""}</div>


                        <a href="#"
                           className= {myIdx === selectedIndex ? "list-group-item list-group-item-action active" :
                               "list-group-item list-group-item-action"}
                           key={myIdx}
                           aria-current="true"
                           onClick={(e)=> onAClick(item,myIdx)}
                        >

                            <div className="d-flex flex-column align-items-baseline">
                                <div className="fw-bolder p-1  ">{rezept?.name}</div>
                                <div className="p-1 d-flex  flex-row  w-100">
                                    <div className="col-auto me-auto w-75">{rezept?.description}</div>
                                    <div  className="px-2 py-0 col-auto">{item.preis  +" CHF"}</div>
                                </div>
                                <div className="p-1 py-0 d-flex flex-row py-0 mtop05">
                                    <div className="py-0"> {item.beschreibung}</div>


                                </div>
                            </div>
                        </a>
                        </div>
                    )

                })
            }
            </div>
        </div>
    );
}