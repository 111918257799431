import {ICompanylist} from "../../Model/CompanyList";
import {Company} from "../../Model/Company";
import React, {useState} from "react";
import {CreateCompany} from "./CompanyFactory";

import {Fab} from "@mui/material";
import {AddIcon} from "../Icons/AddIcon";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import {EditIcon} from "../Icons/EditIcon";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {SaveOutlined} from "@mui/icons-material";

interface ICompanyListComponentModelProps {
    className:string |undefined
    companyList:ICompanylist,
    addCompany?:  (a: Company) => void,
    editCompany?: (a: Company) => void,
    deleteCompany?: (a: Company) => void,
    setCompanyAddress: (a:Company) =>void,
    saveList: ()=> void,
    showButtons: boolean
}
export const CompanyListComponentModel: React.FC<ICompanyListComponentModelProps> = (props:ICompanyListComponentModelProps ) =>  {
    const {companyList,  addCompany,editCompany,deleteCompany, saveList,setCompanyAddress, showButtons } = props;
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [visible, setVisible] = useState(showButtons);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    function newCompany() {
        const c= CreateCompany()
       /* c.entityState = entityState.added*/

        return c;
    }

    let idx:number = 0

    return (
        <div  className={props.className}>
            <div className={   visible || showButtons ? "Register-row-container " : "hidden"}>
            <div className="fab-button">
                    <Fab  color="primary" size="small" aria-label="add"
                          onClick={(e)=> {
                              if (addCompany) {
                                  addCompany(newCompany())
                                  setVisible(false);
                              }
                          }}  >
                        <AddIcon />
                    </Fab>
                </div>
                <div className="fab-button">
                    <Fab size="small"
                         onClick={()=> {
                             if (setCompanyAddress) {
                                 setCompanyAddress(companyList.company[selectedIndex-1]);
                             }
                         }}>

                        <LocalShippingOutlinedIcon />
                    </Fab>
                </div>
                <div className="fab-button">
                    <Fab size="small"
                         onClick={()=> {
                             if(saveList) {
                                 saveList()
                             }}}>

                        <SaveOutlined />
                    </Fab>
                </div>
                <div className="fab-button">

                    <Fab color="secondary" size="small" aria-label="edit"
                         onClick={(e)=> {
                             console.log(("Click"))
                             if (editCompany) {
                                 editCompany(companyList.company[selectedIndex-1])
                                 setVisible(false);
                             }
                         }}>
                        <EditIcon />
                    </Fab>
                </div>
            </div>
            <div className="Register-col" >
                <List sx={{ maxHeight: 220, overflow: 'auto'}} component="div" aria-label="main mailbox folders"  >
                    {

                        companyList.company.reverse().map(item =>
                        {
                            idx = idx +1
                            return (
                                <ListItemButton
                                    classes={selectedIndex === idx ? ".selected" : ""}
                                    key={item.id}
                                    selected={selectedIndex === idx}
                                    onClick={(event) => {
                                        const i = companyList.company.map(x => x.id ).indexOf(item.id);
                                        handleListItemClick(event, i+1);
                                        console.log("idx: " + idx + "  i: " + i + '---' + item.id)
                                        }
                                    }
                                >
                                    <div>
                                        {item.name}
                                    </div>

                                </ListItemButton>

                            )
                        })
                    }
                </List>
            </div>
        </div>
    );
}
