import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {AdressenView} from "./AdressenView";
import {AddressManager} from "../Adresssen/AddressManager";
import React, {useRef, useState} from "react";
import {IRegister} from "../../Model/Register";
import {Kunde} from "../../Model/Kunde";
import {Adresse} from "../../Model/Adresse";
import {Anmeldedaten} from "../../Model/Anmeldedaten";
import {Formik, Form, Field, ErrorMessage, FormikProps, FormikValues} from "formik";

import * as Yup from "yup";

interface KundeComponentModelProps {
    data:Anmeldedaten;
    saveAnmeldedaten: (k: Anmeldedaten) => void
}
export function KundeComponentModel(props:KundeComponentModelProps) {

    const [ anmeldedaten, setAnmeldedaten ] = useState<Anmeldedaten >(props.data);

    const [userid, setUserid] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [password2, setPassword2] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [fullname, setFullname] = useState<string>();
    const [telefon, setTelefon] = useState<string>();
    const [isRechnungsAdresseGleichLieferadresse, setIsRechnungsAdresseGleichLieferadresse] = useState<boolean>(true)

    const [isPasswordCorrect, setIsPasswordCorrect] = useState<boolean>(false);
    const [lieferAdresseState, setlieferAdresseState] = useState<Adresse|undefined>();
    //const [isValid, setIsValid] = useState<boolean>(false);



    const phoneRegExp = new RegExp( "^(\\+?)(\\d{2,4})(\\s?)(\\-?)((\\(0\\))?)(\\s?)(\\d{2})(\\s?)(\\-?)(\\d{3})(\\s?)(\\-?)(\\d{2})(\\s?)(\\-?)(\\d{2})")
    const validationSchema = Yup.object({
        userid:Yup.string().required("UserId ist erforderlich"),
        email: Yup.string().email().required("Email ist erforderlich und muss gültig sein"),
        password:Yup.string().required("Kennwort ist erforderlich"),
        telefon: Yup.string().matches(phoneRegExp, "Die Telefonnummer hat ein ungültiges Format: +41 79 123 45 67"),
        fullname: Yup.string().required("Der Name ist erforderlich:Vorname Name").min(5),
        p2:Yup
            .string()
            .oneOf([Yup.ref("password")], "Die Kennwörter stimmen nicht überein")
            .required("Bitte das Kennwort wiederholen"),
    });

    const initialValues = {
        userid: userid,
        password: password,
        p2: password2,
        email: email,
        title:title,
        fullname:fullname,
        telefon:telefon,
        isRechnungsAdresseGleichLieferadresse:true
    }
    const onSubmit = (data: any) => {
        /*
        setData(new Register(data.m.kunde, data.m.adresse, data.m.firma));
        */
        console.log("Submitted")
        const {userid:userid, password:password, email:email}  = data

        const x = data as Anmeldedaten
        let temp = {
             userid:userid,
             password:password,
             email:email,
            title:title,
            fullname:fullname,
            telefon:telefon,
            isRechnungsAdresseGleichLieferadresse:isRechnungsAdresseGleichLieferadresse

    } as Anmeldedaten
        props.saveAnmeldedaten(x);

    }

    const renderError = (message:string) => <p className="help is-danger">{message}</p>;


    const ref = useRef<FormikProps<FormikValues>>(null);
    // @ts-ignore
    const onFormChange =(e)=>{
            ref?.current?.setSubmitting(true)
            ref?.current?.validateForm(ref.current.values).then(()=>{
                console.log("Form seems to be valid")
                ref?.current?.submitForm().then(()=>console.log("Promise fulfilled"), ()=>console.log("Promise rejected"))
                console.log("Form isValid ")
            }, ()=>console.log("Validation Failed"))


    }

// disabled={!isValid}
    return (
        <Formik
            innerRef={ref}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}


        >
            <Form  onChange={onFormChange} >
                <div className="Register-row">
                    <div className="Register-col">
                        <label className="Register-label" htmlFor="userid">UserId</label>
                        <div className="Register-col">
                            <Field
                                name="userid"
                                type="text"
                                className="input"
                                placeholder="Benutzer Id"
                            />
                            <ErrorMessage name="userid" render={renderError} />
                        </div>
                    </div>
                    <div className="Register-col">
                        <label className="Register-label" htmlFor="password">Kennwort</label>
                        <div className="Register-col">
                            <Field
                                name="password"
                                type="text"
                                className="input"
                                placeholder="Kennwort"
                            />
                            <ErrorMessage name="password" render={renderError} />
                        </div>
                    </div>

                    <div className="Register-col">
                        <label className="Register-label" htmlFor="p2">Bestätigung Kennwort</label>
                        <div className="Register-col">
                            <Field
                                name="p2"
                                type="text"
                                className="input"
                                placeholder="Kennwort"
                            />
                            <ErrorMessage name="p2" render={renderError} />
                        </div>
                    </div>
                    <div className="Register-col">
                        <label className="Register-label" htmlFor="email">E-Mail</label>
                        <div className="Register-col">
                            <Field
                                name="email"
                                type="text"
                                className="input"
                                placeholder="Email Adresse"
                            />
                            <ErrorMessage name="email" render={renderError} />
                        </div>
                    </div>

                    <div className="Register-col">
                        <label className="Register-label" htmlFor="title">Titel</label>
                        <div className="Register-col">
                            <Field
                                name="title"
                                type="text"
                                className="input"
                                placeholder="Titel (Dr. Dipl.Ing etc.)"
                            />
                            <ErrorMessage name="title" render={renderError} />
                        </div>
                    </div>

                    <div className="Register-col">
                        <label className="Register-label" htmlFor="fullname">Name</label>
                        <div className="Register-col">
                            <Field
                                name="fullname"
                                type="text"
                                className="fullname"
                                placeholder="Vorname Name"
                            />
                            <ErrorMessage name="fullname" render={renderError} />
                        </div>
                    </div>

                    <div className="Register-col">
                        <label className="Register-label" htmlFor="telefon">Telefon</label>
                        <div className="Register-col">
                            <Field
                                name="telefon"
                                type="text"
                                className="input"
                                placeholder="+41 79 123 45 67"
                            />
                            <ErrorMessage name="telefon" render={renderError} />
                        </div>
                    </div>

                    <div className="Register-col">
                        <label className="Register-label" htmlFor="isRechnungsAdresseGleichLieferadresse">Rechnungsadresse gleich Lieferadresse</label>
                        <div className="Register-col">
                            <Field
                                name="isRechnungsAdresseGleichLieferadresse"
                                type="checkbox"
                                className="input"
                            />
                            <ErrorMessage name="isRechnungsAdresseGleichLieferadresse" render={renderError} />
                        </div>
                    </div>

                    <div  className="Register-col ">
                        <div className="invisible">
                            <input type={"submit"} />
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
);
}