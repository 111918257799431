import React, {useEffect, useState} from "react";
import {GuestBookDto} from "../Dto/GuestBookDto";
import {activateGuestBook, fetchGuestBookList, fetchLockedGuestBookList} from "../RestApi/GuestBookApiService";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {GuestBookItemShow} from "./GuestBookItemShow";
import {GuestBookActivateDto} from "../Dto/GuestBookActivateDto";
import {Button} from "@mui/material";
export function GuestBookActivateComponent() {
    const [guestBookArray, setGuestBookArray] = useState<GuestBookDto[]|undefined>(undefined)
    const [guestBookActivateArray, setGuestBookActivateArray] = useState<string[]>([])
    const [guestBookDeleteArray, setGuestBookDeleteArray] = useState<string[]>([])
    const [message, setMessage]:[ string, (message: string) => void] = useState("");

    useEffect(() => {
        fetchLockedGuestBookList(
            (data:GuestBookDto[]) =>{
                setGuestBookArray(data)

            },
            (m) =>{ setMessage(m)}
        )
    },[] );

    let idx:number = 0

    const handleActivateChange = (e: { target: { value: any; checked: any; }; }) => {
        const { value, checked } = e.target;
        if (checked) {
            // push selected value in list
            setGuestBookActivateArray(prev => [...prev, value]);
        } else {
            // remove unchecked value from the list
            setGuestBookActivateArray(prev => prev.filter(x => x !== value));
        }
    }

    const handleDeleteChange = (e: { target: { value: any; checked: any; }; }) => {
        const { value, checked } = e.target;
        if (checked) {
            // push selected value in list
            setGuestBookDeleteArray(prev => [...prev, value]);
        } else {
            // remove unchecked value from the list
            setGuestBookDeleteArray(prev => prev.filter(x => x !== value));
        }
    }


    const handleClick = () => {
        let data = guestBookActivateArray.map(x=> new GuestBookActivateDto(x, true, false));
        data.push(... guestBookDeleteArray.map(x=> new GuestBookActivateDto(x, false, true)))
/*        console.log(data)*/


        activateGuestBook(
            data,
            (dta) =>{
                setGuestBookArray(dta)
                setGuestBookActivateArray([])
                setGuestBookDeleteArray([])

            },
            (m) =>{ setMessage(m)}
        )


    };
    return (
        <div className="posts-container">
            <h2>Gästebuch Einträge Freigeben</h2>
            <div className="center errorMessage">{message}</div>
            <div className="Register-col" >
                <List sx={{ maxHeight: 220, overflow: 'auto'}} component="div"   >
                    {
                        guestBookArray !== undefined &&
                        guestBookArray.reverse().map(adr =>
                        {
                            idx = idx +1
                            return (
                                    <div key={idx} className="d-flex flex-row align-items-center">
                                    <GuestBookItemShow guestBook={adr}/>
                                        <label className="px-3">
                                            <input type="checkbox"
                                                   value={adr.id}
                                                   onChange={handleActivateChange}
                                            />
                                            Activate
                                        </label>
                                        <label className="px-3">
                                            <input type="checkbox"
                                                   value={adr.id}
                                                   onChange={handleDeleteChange}
                                            />
                                            Delete
                                        </label>
                                    </div>

                            )
                        })
                    }
                </List>

                <button type="button" className="btn btn-primary" onClick={handleClick} >Senden</button>
            </div>
        </div>
    );
}