import {Company} from "../../Model/Company";
import React, {useState} from "react";
import {ICompanylist} from "../../Model/CompanyList";
import {CompanyListComponentModel} from "./CompanyListComponentModel";
import {EditCompanyComponentModel} from "./EditCompanyComponentModel";
import {CompanySimpleData} from "../../Model/CompanySimpleData";
import {SingleCompanyComponentModel} from "./SingleCompanyComponentModel";
import {AdressenView} from "../Kunde/AdressenView";


interface ICompanyComponentManagerProps {
    companyList:ICompanylist;
}
export function CompanyComponentManager(props:ICompanyComponentManagerProps) {
    const [companyList, setCompanyList] = useState<ICompanylist |undefined>(props.companyList)
    const [editCompany  , setEditCompany] = useState<Company | undefined>(undefined);

    function  addCompany1  (a:Company)        {
        setEditCompany( a) ;
    }
    function  editCompany1  (a:Company)        {
        setEditCompany( a) ;
    }
    function  deleteCompany1  (a:Company)        {
        setEditCompany( a) ;
    }

    function  cancelEdit  ()        {
        setEditCompany( undefined) ;
    }

    function  saveList  ()        {
        setEditCompany( undefined) ;
    }


    function saveCompanyData (k: Company) {
        if(companyList) {
            const idx=companyList.company.map(x =>x.id).indexOf(k.id)
            if(idx > -1) {
                companyList.company[idx] = k
            }
            else {
                companyList.company.push(k)
            }

        }

        setEditCompany(undefined) ;

    }

    if(!companyList ) {
        return <div>Keine Firmenliste übergeben</div>
    }

    return (
        <div>
        <SingleCompanyComponentModel visible={editCompany !== undefined}
                                     saveCompanyData={saveCompanyData}
                                     editCompanyData={editCompany} cancel={cancelEdit} />

        <CompanyListComponentModel className={editCompany ? "hidden" : "" }
                                    companyList={companyList}
                                   setCompanyAddress={(a)=>{}}
                                   addCompany={addCompany1}
                                   editCompany={  editCompany1}
                                   deleteCompany={ deleteCompany1}
                                   saveList={saveList}
                                   showButtons={ editCompany === undefined}

        ></CompanyListComponentModel>
        </div>
    );
}