import {publicDecrypt} from "crypto";
import {Kunde} from "../../Model/Kunde";
import {v4} from "react-native-uuid/dist/v4";




export function CreateKunde() {
    const kunde= new Kunde(v4().toString());
    return kunde;
}


