import {createAsyncThunk} from "@reduxjs/toolkit";
import {ILoginDto} from "../../Content/Dto/ILoginDto";
import http from "../../http-common";
import {AxiosResponse} from "axios/index";
import {ILoginResponseDto} from "../../Content/Dto/LoginResponseDto";
import {IEmailConfirmationDto} from "../../Content/Dto/IEmailConfirmationDto";

export const confirmEmailAction = createAsyncThunk(
    'confirm/email',
    async (data: IEmailConfirmationDto, { rejectWithValue }) => {
        try {
            console.log('vor await email confirmed')
            var response = await http.post<IEmailConfirmationDto,AxiosResponse>
            ("/api/register/confirmEmail", data);
            /*   const token = jwt_decode(response.token);

               // store user's token in local storage*/
            console.log('nach await email confirmed')

            return response.data
        } catch (error) {
            // return custom error message from API if any
            // @ts-ignore
            if (error.response && error.response.data.message) {
                // @ts-ignore
                return rejectWithValue(error.response.data.message)
            } else {
                // @ts-ignore
                return rejectWithValue(error.message)
            }
        }
    }
)
